.off-canvas {
  &.is-active {
    overflow: hidden;

    @include off-canvas-breakpoint {
      overflow: visible;
    }
  }

  &-overlay {
    background-color: rgba(#000, .6);
  }

  &-menu {
    @include font-size(18px);
    position: fixed;
    z-index: $zindex-navbar-fixed + 10;
    background-color: $brand-primary;
    color: #fff;
    text-align: left;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &-list {
      a {
        display: block;
        padding: $suh $sub;
        color: #fff;

        &:hover,
        &:focus {
          color: #fff;
        }
      }

      .active a {
        background-color: #fff;
        color: $brand-primary;
      }
    }

    &-title {
      @include clearfix;
      @include font-size(20px, 3);
      height: $navbar-height;
      margin-bottom: $suh;
      padding-left: $sub;
      padding-right: $sub * .75;
      border-bottom: 1px solid rgba(#fff, .2);
      color: rgba(#fff, .75);
      font-family: $font-regular;

      .off-canvas-toggle {
        float: right;
        position: relative;
        bottom: 1px;
        padding-left: $suh;
        padding-right: $suh;
        color: #fff;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
