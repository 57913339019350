.person {
  &-link {
    display: block;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;

      .person {
        &-name,
        &-title {
          text-decoration: underline;
        }
      }
    }
  }

  &-avatar {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $suh/2;
    background-size: 40px;

    &-image {
      margin-bottom: $suh/2;
    }
  }

  &-title {
    @include font-size(12px);
    color: rgba(#fff, .5);
    text-transform: uppercase;
  }

  &-decoration {
    display: inline-block;
    width: 32px;
    height: 5px;
    background-image: url("../images/decoration@2x.png");
    background-position: 0% 0%;
    background-size: 8px 5px;
    background-repeat: repeat-x;

    .no-backgroundsize & {
      background-image: url("../images/decoration.png");
    }
  }
}
