&-visible {
  display: block;

  @include off-canvas-breakpoint {
    display: none;
  }
}

&-hidden {
  display: none;

  @include off-canvas-breakpoint {
    display: block;
  }
}
