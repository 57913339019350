&-pactio {
  @include bg-full("list-bg-black.jpg");
  background-color: #323232;
  color: rgba(#fff, .7);

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: #92d4d6;
      }
    }

    &-logo {
      width: 230px;

      @include query(xs) {
        width: 334px;
      }
    }
  }

  .btn {
    border-color: #fff;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #000;
    }
  }
}
