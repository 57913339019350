&-videos {
  background-color: #000;
  color: lighten(#000, 30%);

  .section {
    &-supertitle {
      margin-bottom: $sub * 1.5;
      color: #fff;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .post {
    &-short {
      @include post-dark;
      @include post-video;

      @include query(sm) {
        height: $sub * 20;
      }

      @include query(md) {
        height: $sub * 18;
      }

      @include query(lg) {
        height: $sub * 16;
      }

      &-container {
        @include make-sm-column(4);
      }
    }
  }
}
