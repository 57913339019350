@mixin background-image($filename, $extension, $background-size, $retina-suffix: "@2x") {
  background-image: url("../images/#{$filename}.#{$extension}");

  @include hidpi {
    background-image: url("../images/#{$filename}#{$retina-suffix}.#{$extension}");
    background-size: $background-size;
  }
}

// full-width background images
@mixin bg-full($filename) {
  background-image: url("/images/#{$filename}?resize=480");

  @include query(xs) { background-image: url("/images/#{$filename}?resize=768"); }
  @include query(sm) { background-image: url("/images/#{$filename}?resize=1024"); }
  @include query(md) { background-image: url("/images/#{$filename}?resize=1200"); }
  @include query(lg) { background-image: url("/images/#{$filename}"); }

  @include hidpi {
    background-image: url("/images/#{$filename}?resize=960");

    @include query(xs) { background-image: url("/images/#{$filename}?resize=1536"); }
    @include query(sm) { background-image: url("/images/#{$filename}?resize=2048"); }
    @include query(md) { background-image: url("/images/#{$filename}?resize=2400"); }
    @include query(lg) { background-image: url("/images/#{$filename}"); }
  }
}

@mixin background-images($images...) {
  $background-images: ();
  $background-images-hidpi: ();
  $background-sizes: ();

  @each $image in $images {
    $filename: map-get($image, "filename");
    $extension: map-get($image, "extension");
    $size: map-get($image, "size");
    $url: url("../images/#{$filename}.#{$extension}");
    $url-hdpi: url("../images/#{$filename}@2x.#{$extension}");

    $background-images: append($background-images, $url, comma);
    $background-images-hidpi: append($background-images-hidpi, $url-hdpi, comma);
    $background-sizes: append($background-sizes, $size, comma);
  }

  background-image: $background-images;

  @include hidpi {
    background-image: $background-images-hidpi;
    background-size: $background-sizes;
  }
}

@mixin skrollr {
  &.skrollable-before,
  &.skrollable-between {
    @content;
  }
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin logo($type: "light") {
  //background-image: url("../images/logo-#{$type}.png");
  //background-image: url("../images/logo-#{$type}.svg"), none;
  background-image: url("../images/logo-#{$type}.png");
}
