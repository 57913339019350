&-blog {
  padding-top: 0;
  padding-bottom: $sub;
  background-color: #000;
  color: #fff;

  @include query(sm) {
    padding-bottom: $sub * 3;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .post-short {
    @include post-horizontal;
    height: $sub * 8;

    @include query(sm) {
      height: $sub * 10;
    }

    @include query(md) {
      height: $sub * 13;
    }

    &-container {
      @include make-md-column(6);
    }

    &-title {
      @media (max-width: $screen-xs-min) {
        font-size: 18px;
      }
    }

    &-footer {
      @media (max-width: $screen-xs-min) {
        font-size: 14px;
      }
    }
  }
}
