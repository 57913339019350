&-content {
  display: none;
  opacity: 0;
  transition: all .3s;

  @include query($grid-float-breakpoint) {
    display: block;
  }

  .container-fluid {
    padding-top: $sub;
  }

  .post {
    &-short {
      @include query(md) {
        height: $sub * 21;
      }

      @include query(lg) {
        height: $sub * 19;
      }

      &-body {
        display: none;
      }
    }
  }

  &-item {
    display: none;

    &.is-visible {
      display: block;
    }
  }
}
