&-intro {
  padding-top: $sub;
  padding-bottom: 0;
  overflow: visible;

  @include query(sm) {
    padding-top: $sub * 2;
    text-align: left;
  }

  @include query(md) {
    padding-top: $sub * 3;
  }

  .section {
    &-title {
      @include query(sm) {
        margin-bottom: $sub * 1.5;
      }

      &.has-underline {
        &::after {
          content: "";
          display: block;
          width: $sub * 2;
          margin-top: $sub;
          margin-left: auto;
          margin-right: auto;
          border-bottom: 1px solid #000;

          @include query(sm) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      br {
        display: none;

        @include query(lg) {
          display: inline;
        }
      }

      ~ p:last-of-type {
        @include query(sm) {
          margin-bottom: $sub * 2;
        }
      }
    }

    &-image {
      @include center(850px);
      display: block;
      margin-top: $sub;
      margin-bottom: $sub * -2.5;

      @include query(xs) {
        margin-top: $sub * 2;
        margin-bottom: $sub * -3;
      }

      @include query(sm) {
        margin-top: $sub;
        margin-bottom: $sub * -4;
      }

      @include query(md) {
        margin-top: $sub * 2;
        margin-bottom: $sub * -4.5;
      }
    }
  }

  h3 {
    @include font-size(28px, 1.5);
    color: $brand-primary;
    font-family: $font-light;

    @include query(sm) {
      margin-bottom: $sub * 1.5;
    }
  }

  .platforms {
    @include query(md) {
      margin-bottom: $sub * 3;
    }
  }

  + section {
    padding-top: $sub * 4;

    @include query(sm) {
      padding-top: $sub * 5.5;
    }

    @include query(md) {
      padding-top: $sub * 7;
    }
  }
}
