$blue: #0d67bf;

&-intro {
  @include bg-full("doctor/bg.jpg");
  background-color: #cccdce;
  color: #474b52;

  .section {
    &-supertitle {
      color: #fff;

      &-border {
        border-bottom-color: $blue;
      }
    }

    &-image {
      margin-bottom: $sub * -1;

      img {
        max-width: 80%;
      }

      @include query(sm) {
        margin-bottom: $sub * -1.5;

        img {
          max-width: 100%;
        }
      }

      @include query(md) {
        margin-right: $sub * 2;
        margin-bottom: $sub * -2;
      }
    }

    &-title {
      margin-bottom: $suh;
      color: $blue;

      @include query(sm) {
        margin-bottom: $sub;
      }
    }
  }

  .separator {
    &-border {
      color: #8e9092;
    }

    &-dots {
      color: $blue;
    }
  }

  h3 {
    color: $blue;
  }

  + .section {
    padding-top: $sub * 2.5;

    @include query(sm) {
      padding-top: $sub * 3;
    }

    @include query(md) {
      padding-top: $sub * 4;
    }
  }
}
