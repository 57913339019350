&.is-search {
  .navbar-nav {
    li:last-child {
      a {
        opacity: 1;
      }

      i {
        @extend .fa-close;
        transform: scale(1.4) rotate(360deg);
      }
    }
  }
}
