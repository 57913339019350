&-pic {
  .section {
    &-supertitle {
      &-border {
        border-bottom-color: #fb9382;
      }
    }

    &-title {
      color: #20b4c9;
    }
  }
}
