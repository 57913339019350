&-policy {
  .container-fluid {
    > * {
      @include center(80rem);
      text-align: left;
    }
  }

  h3 {
    color: $brand-primary;
    font-size: 28px;

    &:first-child {
      color: #000;
      font-size: 34px;
    }
  }
}
