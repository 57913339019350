&-process {
  background-color: #f2f2f2;

  .section {
    &-title {
      @include center(800px);
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub *3;
      }

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }

    &-image {
      margin-bottom: $sub * 2;
      overflow-x: auto;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }
    }

    &-note {
      @include font-size(20px);
      display: inline-block;
      margin-bottom: 0;
      padding: $suh;
      background-color: #fff;
      color: #000;

      @include query(sm) {
        br {
          display: none;
        }
      }
    }
  }
}
