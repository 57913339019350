&-team {
  padding-bottom: $sub;
  background-color: #f2f2f2;

  @include query(sm) {
    padding-bottom: 0;
  }

  .container-fluid {
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .team {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;

    @include query(sm) {
      flex-direction: row;
      flex-flow: wrap;
    }

    &-member {
      $border: 1px solid rgba(#000, .1);
      padding: $sub;
      border-bottom: $border;

      &:last-child {
        border-bottom-style: none;
      }

      @include query(sm) {
        flex: 0 0 (100%/2);
        padding: $sub * 2;
        border-right: $border;

        &:nth-child(2n) {
          border-right-style: none;
        }

        &:nth-last-child(2) {
          border-bottom-style: none;
        }
      }

      @include query(md) {
        flex: 0 0 (100%/3);

        &:nth-child(2n) {
          border-right-style: solid;
        }

        &:nth-child(3n) {
          border-right-style: none;
        }

      }

      &-description {
        max-width: 240px;
      }

      &-past {
        &-description {
          margin-bottom: 0;
        }
      }
    }
  }
}
