&-work {
  .navbar {
    .no-touch & {
      background-color: transparent;
    }
  }

  .section-partners {
    padding-bottom: $sub * 2;
  }
}
