&-news {
  background-color: #f2f2f2;

  .section {
    &-supertitle {
      margin-bottom: $sub * 1.5;

      @include query(xs) {
        margin-bottom: $suh;

        &-border {
          display: none;
        }
      }
    }

    &-timeline {
      @include query(xs) {
        $size: $sub;
        position: relative;
        padding-top: $size;
        overflow: hidden;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: $size/-2;
          width: $size;
          height: $size;
          border: 2px solid $brand-primary;
          border-radius: 50%;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: $size;
          bottom: 0;
          left: 50%;
          margin-left: -1px;
          width: 2px;
          background-color: $brand-primary;
        }
      }
    }
  }

  .article {
    position: relative;
    margin-bottom: $sub;
    padding-left: 0;
    padding-right: 0;

    @include query(xs) {
      width: (100%/2);
    }

    &-inner {
      overflow: visible;

      @include query(xs) {
        max-width: 360px;
      }
    }

    &-content {
      @include query(xs) {
        position: relative;
        overflow: visible;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          margin-top: -1em;
          border: 1em solid transparent;
        }

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          margin-top: -9px;
          width: 18px;
          height: 18px;
          border: 4px solid #f2f2f2;
          border-radius: 50%;
          background-color: $brand-primary;
        }
      }
    }

    &:nth-of-type(odd) {
      @include query(xs) {
        float: left;
        clear: left;
      }

      .article-inner {
        @include query(xs) {
          margin-left: auto;
          margin-right: $sub * 1.5;
        }

        @include query(sm) {
          margin-right: $sub * 2;
        }
      }

      .article-content {
        &::before {
          right: -2em;
          border-left-color: #fff;
        }

        &::after {
          @include query(xs) {
            right: $sub * -1.5 - 9px;
          }

          @include query(sm) {
            right: $sub * -2 - 9px;
          }
        }
      }
    }

    &:nth-of-type(even) {
      @include query(xs) {
        float: right;
        clear: right;
      }

      .article-inner {
        @include query(xs) {
          margin-left: $sub * 1.5;
          margin-right: auto;
        }

        @include query(sm) {
          margin-left: $sub * 2;
        }
      }

      .article-content {
        &::before {
          left: -2em;
          border-right-color: #fff;
        }

        &::after {
          @include query(xs) {
            left: $sub * -1.5 - 9px;
          }

          @include query(sm) {
            left: $sub * -2 - 9px;
          }
        }
      }
    }
  }
}
