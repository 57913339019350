&-intro {
  .section {
    &-overlay {
      @include query(sm) {
        padding-top: $sub * 5;
      }

      @include query(md) {
        padding-top: $sub * 7;
        padding-bottom: $sub * 4;
      }

      @include query(lg) {
        padding-bottom: $sub * 6;
      }
    }

    &-title {
      color: $brand-primary;
    }
  }
}
