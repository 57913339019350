$brown: #e75e47;

&-intro {
  background-image: url("/images/corner/bg.jpg");
  background-color: #f2f2f2;

  h3 {
    color: $brown;
  }

  .section {
    &-title {
      color: $brown;
    }

    &-image {
      margin-bottom: $sub * -2;
    }

    &-text {
      color: #6a6a6a;
    }
  }

  .separator {
    &-border {
      color: #a49e9b;
    }

    &-dots {
      color: $brown;
    }
  }

  + .section {
    padding-top: $sub * 4;
  }
}
