.carousel {
  &-indicators {
    $size: 20px;

    bottom: $sub * 1.5;
    font-size: 0;

    li {
      height: auto;
      margin: 0;
      padding: 0;
      border-style: none;
      vertical-align: middle;
      font-size: 20px;
      text-indent: 0;

      &, &.active {
        width: 28px;
      }

      span {
        @extend .fa;
        @extend .fa-carousel;
        display: block;
        color: #fff;
      }

      &.active {
        height: auto;
        background-color: transparent;

        span {
          @extend .fa-carousel-active;
          color: $brand-primary;
        }
      }
    }
  }
}
