.footer {
  @include font-size(14px);
  padding-top: $sub;
  padding-bottom: $sub;
  background-color: #000;
  text-align: center;
  color: darken(#fff, 25%);

  @include query(sm) {
    padding-top: $sub * 2;
  }

  @include query(md) {
    text-align: left;
  }

  h4 {
    @include font-size(16px);
    margin-bottom: $suh;
    font-family: $font-medium;
    color: #fff;

    @include query(sm) {
      margin-bottom: $sub;
    }
  }

  a {
    color: darken(#fff, 25%);

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .row:last-child {
    margin-top: $suh;

    @include query(sm) {
      margin-top: $sub;
    }
  }

  &-brand {
    display: inline-block;
    position: relative;
    bottom: $suh/2 - 1px;
    margin-bottom: $sub;
  }

  &-social {
    margin-bottom: $suh;
    line-height: 0;

    li {
      display: inline-block;
      margin-left: $suh/4;
      margin-right: $suh/4;

      @include query(md) {
        margin-left: 0;
        margin-right: 0;
      }

      @include query(lg) {
        margin-left: $suh/4;
        margin-right: $suh/4;
      }
    }

    a {
      display: inline-block;
      overflow: hidden;
      border-radius: 4px;
    }

    .text-hide {
      width: 30px;
      height: 30px;
      background-size: 30px;
      background-repeat: no-repeat;
    }

    i {
      font-size: 18px;
      color: #fff;
    }

    @each $network in facebook, twitter, google-plus, linked-in {
      &-#{$network}.text-hide {
        background-image: url(../images/#{$network}-square@2x.png);
      }
    }
  }

  &-copyright {
    margin-bottom: $suh;
    color: rgba(#fff, .4);
  }

  &-top {
    position: relative;
    bottom: 8px;

    @include query(md) {
      text-align: right;
    }
  }

  .arrow {
    $size: 38px;

    width: auto;
    height: auto;
    font-size: $size;
    line-height: $size;
    opacity: .75;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &, &-label {
      display: inline-block;
      vertical-align: middle;
    }

    &-label {
      color: rgba(#fff, .5);
      margin-right: $suh;
    }
  }

  &-blog {
    .footer-social {
      a {
        border-radius: 0;
      }
    }
  }

  &-landing {
    text-align: center;
  }
}
