&-partners {
  padding: 0 !important;
  background-color: #fff;

  .container-fluid {
    width: auto;
    max-width: none;
    padding: 0;
  }

  .partners {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .partner {
    $border: 1px solid #ebebeb;
    flex: 0 0 100%;
    border-bottom: $border;

    @include query(sm) {
      &:nth-child(2n) {
        border-left: $border;
      }
    }

    @include query(md) {
      &:nth-child(2n) {
        border-left-style: none;
      }

      &:nth-child(3n+2) {
        border-left: $border;
        border-right: $border;
      }
    }

    @include query(sm) {
      flex-basis: (100%/2);
    }

    @include query(md) {
      flex-basis: (100%/3);
    }

    img {
      @extend .img-responsive;
      max-width: 70%;

      @include query(sm) {
        max-width: 100%;
      }
    }

    &-category {
      @include font-size(12px);
      margin-bottom: 0;
      padding-top: $suh * 1.5;
      padding-bottom: $suh * 1.5;
      background: #f9f9f9;
      font-family: $font-medium;
      text-transform: uppercase;
      letter-spacing: 3px;
    }

    &-logo,
    &-body {
      padding-left: $sub;
      padding-right: $sub;
    }

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;

      @include query(sm) {
        height: 200px;
      }
    }

    &-body {
      padding-bottom: $sub * 2;

      @include query(sm) {
        border-style: none;
      }

      p {
        @include center(300px);
      }
    }
  }
}
