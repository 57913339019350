&-start {
  background-color: #fff;
  border-top: 2px solid #d9d9d9;

  .section-blog-post-pager + & {
    background-color: #e9e9e9;
  }

  @include query(sm) {
    text-align: left;
  }

  h3 {
    color: $brand-primary;
    font-family: $font-regular;
  }

  p {
    color: #404040;

    @include query(sm) {
      margin-bottom: 0;
    }
  }

  .section-btn {
    @include query(sm) {
      text-align: right;
    }
  }

  .btn {
    @include query(sm) {
      margin-top: $sub * 1.5;
      padding-left: $sub;
      padding-right: $sub;
    }

    @include query(md) {
      padding-left: $sub * 2;
      padding-right: $sub * 2;
    }
  }


  .section-blog-post-comments + & {
    border-bottom: 2px solid darken(#fff, 15%);
  }

  .section-blog-authors + & {
    border-top: 2px solid darken(#fff, 15%);
  }
}
