@mixin border-box-sizing {
  *, *::before, *::after {
    box-sizing: border-box;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin font-size($font-size, $line-height: 1) {
  font-size: $font-size;
  line-height: ($line-height * $line-height-computed) / $font-size;
}

@mixin query($size) {
  @if $size == xs {
    @media (min-width: $screen-xs-min) { @content; }
  }
  @else if $size == sm {
    @media (min-width: $screen-sm-min) { @content; }
  }
  @else if $size == md {
    @media (min-width: $screen-md-min) { @content; }
  }
  @else if $size == lg {
    @media (min-width: $screen-lg-min) { @content; }
  }
  @else {
    @media (min-width: $size) { @content; }
  }
}

@mixin center($max-width: false) {
  @if $max-width != false {
    max-width: $max-width;
  }
  margin-left: auto;
  margin-right: auto;
}

@mixin hidpi($ratio: 1.3) {
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: #{round($ratio*96)}dpi),
  only screen and (min-resolution: #{$ratio}dppx) {
    @content;
  }
}

@mixin list-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin vertically-center {
  height: 100%;

  &::before {
    content: "";
    height: 100%;
    margin-left: -0.25em;
  }

  &::before,
  > * {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
