.article {
  @include font-size(15px);

  @include query(xs) {
    padding: $suh;
  }

  @include query(md) {
    padding: $sub;
  }

  &-inner {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
  }

  &-image {
    @extend .img-responsive;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &-content {
    padding: $sub $suh;

    @include query(sm) {
      padding: $sub;
    }

    @include query(lg) {
      padding: $sub * 1.5;
    }
  }

  &-title {
    @include font-size(22px, 1.25);
    margin-bottom: $sub;
    font-family: $font-light;

    @include query(sm) {
      margin-bottom: $sub * 1.5;
    }
  }

  .btn-link {
    @include font-size(14px);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
