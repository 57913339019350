.container-fluid {
  @include query(sm) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @include query(lg) {
    width: $screen-lg-min;
  }

  &.is-full {
    width: auto;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
}
