.list {
  &-romb {
    padding-left: $sub + 3px;
    list-style-type: none;
    text-align: left;

    > li {
      position: relative;

      &::before {
        content: "\e601"; // .fa-carousel
        display: inline-block;
        position: absolute;
        left: $suh * -1.5;
        font-family: "fontawesome";
        font-size: 18px;
        color: darken(#fff, 35%);

        @include query(sm) {
          left: -$sub;
        }
      }
    }
  }

  &-enumerate {
    counter-reset: question;

    @include query(sm) {
      @include list-unstyled;

      > li,
      > dt {
        $size: $sub * 1.25;
        position: relative;

        &::before,
        &::after {
          position: absolute;
          top: -2px;
          left: 0;
          display: inline-block;
          width: $size;
          height: $size;
        }

        &::before {
          content: "";
          border: 1px solid darken(#fff, 15%);
          transform: rotate(45deg);
        }

        &::after {
          counter-increment: question;
          content: counter(question);
          line-height: $size - 2px; // border width
          color: $brand-primary;
          text-align: center;
          font-size: 12px;
        }
      }
    }

    > li,
    > dt,
    > dd {
      @include query(sm) {
        padding-left: $sub * 2.5;
      }
    }

    > li:last-child > *:last-child {
      margin-bottom: 0;
    }
  }

  &-dash {
    padding-left: 0;
    list-style: none;
    font-family: $font-medium;
    color: tint($brand-primary, 20%);

    li {
      &::before {
        content: "\2014";
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }
}
