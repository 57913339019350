@mixin post-horizontal {
  @include clearfix;
  width: 100%;

  .post-short {
    &-image,
    &-inner {
      height: 100%;
      float: left;
    }

    &-image {
      width: 40%;

      @include query(sm) {
        width: 50%;
      }
    }

    &-inner {
      position: relative;
      width: 60%;
      padding-left: $suh;
      padding-right: $suh;

      @include query(sm) {
        width: 50%;
        padding-left: $sub;
        padding-right: $sub;
      }
    }

    &-footer {
      position: absolute;
      right: $suh;
      bottom: $suh;
      left: $suh;

      @include query(sm) {
        right: $sub;
        bottom: $sub;
        left: $sub;
      }
    }
  }
}
