&-author {
  background-color: #f5f5f5;
  border-top: 2px solid #d9d9d9;
  color: #818181;

  .section {
    &-supertitle {
      margin-bottom: $sub;
      color: #000;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-image {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: 0;
        text-align: right;
      }
    }

    &-text {
      @include query(sm) {
        text-align: left;
      }
    }
  }

  img {
    margin-top: $suh;
  }

  h3 {
    @include font-size(20px);
    font-family: $font-regular;
  }

  .social {
    margin-bottom: 0;

    @include query(sm) {
      margin-top: $sub * 2;
    }

    li {
      margin-bottom: $suh;

      @include query(sm) {
        margin-bottom: 0;
      }
    }
  }
}
