.search {
  &-mobile {
    margin-bottom: $suh;
    padding-left: $suh;
    padding-right: $suh;

    .form-control {
      border-style: none;
      border-radius: 0;
      background-color: shade($brand-primary, 15%);
      color: #fff;

      &::placeholder {
        color: #fff !important;
      }
    }
  }

  &-desktop {
    min-height: $sub * 10;

    form {
      @include font-size(62px, 3);
      margin-bottom: $sub;
    }

    .input-group-addon,
    .form-control {
      border-style: none;
      background-color: transparent;
      box-shadow: none;
      font-size: inherit;
      line-height: inherit;
    }

    .input-group-addon {
      color: darken(#fff, 20%);
    }

    .form-control {
      height: auto;
      color: #fff;

      &::placeholder {
        color: darken(#fff, 15%);
      }
    }
  }
}
