.team {
  @include font-size(14px);
  margin-bottom: $sub * 2;

  &-member {
    @include query(lg) {
      padding-left: $sub * 2;
      padding-right: $sub * 2;
    }

    &-avatar {
      margin-bottom: $sub;

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-name {
      @include font-size(18px);
      margin-bottom: $suh;

      small {
        @include font-size(12px);
        display: block;
        color: $brand-primary;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    &-description {
      @include center(300px);
      margin-bottom: $suh;

      @include query(sm) {
        max-width: none;
      }
    }

    &-past {
      &-title {
        @include font-size(12px);
        @include center(80px);
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &-description {
        @include center(300px);

        a {
          color: inherit;

          &:hover,
          &:focus {
            color: lighten(#000, 30%);
          }
        }
      }
    }

    .separator {
      &-border {
        color: darken(#fff, 25%);
      }

      @include query(sm) {
        position: absolute;
        top: (50px - 17px); // (avatar height / 2) - (divider height / 2)
        right: -17px;
        margin: 0;
      }

      .carousel .item:last-child & {
        display: none;
      }
    }
  }

  &-all {
    border-top: 2px solid darken(#fff, 10%);

    a {
      @include font-size(12px);
      display: block;
      padding: $sub * 1.5 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: lighten(#000, 40%);

      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }
}
