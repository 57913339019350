&-person {
  padding-top: $sub * 4;
  color: lighten(#000, 30%);

  @include query(sm) {
    padding-top: $sub * 5;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;
      color: #000;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-image {
      margin-bottom: $sub;
    }

    &-title {
      @include font-size(28px);
      margin-bottom: $sub;
      color: $brand-primary;
      font-family: $font-light;
    }

    &-text {
      @include center(500px);
    }
  }

  .social {
    margin-bottom: 0;

    @include query(sm) {
      margin-top: $sub * 2;
    }

    li {
      margin-bottom: $suh;

      @include query(sm) {
        margin-bottom: 0;
      }
    }

    i {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }
}
