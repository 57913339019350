&.is-interacting {
  .navbar-nav {
    > li > a {
      opacity: .5;

      &:hover,
      &:active {
        opacity: 1;
      }
    }
  }
}
