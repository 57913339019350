&-intro {
  overflow: visible;
  padding-top: $sub * 4;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include query(sm) {
    padding-top: $sub * 5;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;
    }

    &-lead {
      max-width: 460px;
    }

    &-row {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::before,
      &::after {
        content: normal;
      }

      @include query(sm) {
        flex-direction: row;
        align-items: center;
      }
    }

    &-image {
      order: 2;

      @include query(sm) {
        flex: 3;
        order: 1;
        margin-right: $sub * 1;
      }

      @include query(md) {
        margin-right: $sub * 1.5;
      }

      @include query(md) {
        margin-right: $sub * 2;
      }

      img {
        @extend .img-responsive;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;

        @include query(sm) {
          max-width: 100%;
          margin-right: 0;
        }
      }
    }

    &-text {
      order: 1;

      @include query(sm) {
        flex: 2;
        order: 2;
        text-align: left;
      }

      p {
        @include query(md) {
          max-width: 340px;
        }

        &:last-child {
          @include query(sm) {
            margin: 0;
          }
        }
      }
    }
  }

  .separator {
    margin-top: $sub;
    margin-bottom: $sub;

    @include query(sm) {
      margin-top: $sub * 2;
      margin-bottom: $sub * 2;
    }
  }

  .stores {
    margin-bottom: $sub;

    a {
      display: inline-block;
      margin-left: $suh;
    }
  }
}
