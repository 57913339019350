@mixin off-canvas-init {
  backface-visibility: hidden;
  transition: $off-canvas-transition-duration transform;
}

@mixin off-canvas-breakpoint {
  @if $off-canvas-breakpoint {
    $direction: nth($off-canvas-breakpoint, 1);
    $breakpoint: nth($off-canvas-breakpoint, 2);

    @if $direction == to {
      @media (min-width: ($breakpoint)) { @content; }
    }
    @else if $direction == from {
      @media (max-width: ($breakpoint - 1px)) { @content; }
    }
    @else {
      @warn "#{$direction} is not a valid breakpoint direction!";
    }
  }
}
