&-difference {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #64bb50;
  color: #000;
  overflow: hidden;

  .section {
    &-supertitle {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $suh;
      }
    }

    &-title {
      color: #fff;
    }

    &-lead {
      max-width: 500px;
    }
  }

  .img-outer {
    max-width: 978px;
  }

  .img-inner {
    padding-bottom: 53%;
  }
}
