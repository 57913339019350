.skip-to-main {
  z-index: $zindex-navbar;

  &:active,
  &:focus {
    position: fixed;
    top: $sub * 3;
    left: 50%;
    margin-left: $sub * -3.5;
  }
}
