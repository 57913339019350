&.is-dark {
  .no-touch & {
    background-color: transparent;
  }

  @include skrollr {
    .navbar-nav {
      a {
        &,
        &:hover,
        &:focus {
          color: #454545;
        }
      }
    }

    .navbar-border {
      background-color: rgba(#000, .9);
    }

    li.active {
      .navbar-border {
        background-color: #000;
      }
    }

    .logo {
      @include logo("black");

      &-blog {
        @include logo("blog-black");
      }
    }
  }
}
