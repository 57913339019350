$partners: (
  ycombinator: 229 70,
  startx: 126 70,
  accelpartners: 170 70,
  500startups: 100 70,
  sequoia: 170 30
);

&-partners {
  padding-top: 0;
  background-color: #222;
  color: #fff;

  .section {
    &-supertitle {
      margin-bottom: $sub;
      color: #fff;

      @include query(xs) {
        br {
          display: none;
        }
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .partners {
    font-size: 0;
  }

  .partner {
    display: block;
    margin: $sub;
    margin-left: auto;
    margin-right: auto;
    opacity: .5;
    text-align: center;

    @each $partner, $dimensions in $partners {
      &-#{$partner} {
        width: nth($dimensions, 1) * 1px;
        height: nth($dimensions, 2) * 1px;
        background-image: url("../images/#{$partner}@2x.png?sprite=partners");
        background-repeat: no-repeat;
        background-size: nth($dimensions, 1) * 1px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    @include query(sm) {
      display: inline-block;
      vertical-align: middle;
      margin: $sub;
    }

    @include query(lg) {
      margin: $sub $sub*0.8
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
