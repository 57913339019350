$blue: #20b4c9;
$orange: #ff6c53;

&-intro {
  @include bg-full("pic/bg.jpg");
  position: relative;
  z-index: 1;
  background-color: #1d2127;
  color: rgba(#fff, .7);

  .section {
    &-supertitle {
      color: #fff;

      &-border {
        border-bottom-color: $orange;
      }
    }

    &-title {
      color: $blue;
    }

    &-decoration {
      display: none;

      @include query(sm) {
        display: block;
        flex: 1;
      }

      img {
        @extend .img-responsive;
      }
    }

    &-image {
      margin-left: $sub;
      margin-right: $sub;
      margin-bottom: $sub * -2;

      @include query(sm) {
        flex: 1;
        margin-bottom: $sub * -4;
      }

      img {
        @include query(md) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &-text {
      @include query(sm) {
        flex: 1;
      }
    }
  }

  a {
    color: $orange;

    &:hover,
    &:focus {
      color: adjust-color($orange, $lightness: -15%);
    }
  }

  h3 {
    color: $blue;
  }

  + .section {
    padding-top: $sub * 3;

    @include query(sm) {
      padding-top: $sub * 5;
    }
  }
}
