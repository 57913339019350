.vertically-center {
  @include vertically-center;
}

.align-left {
  margin-right: auto;
}

.align-right {
  margin-left: auto;
}
