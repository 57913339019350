&-intro {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #555;
  background-repeat: no-repeat, no-repeat;
  background-position: 50% 50%, 50% 50%;
  background-size: cover, cover;
  color: #fff;

  .container-fluid {
    position: relative;
  }

  .section {
    &-overlay {
      padding-top: $sub * 4;
      padding-bottom: $sub * 2;
      background-color: rgba(#000, .6);
    }

    &-bg {
      background-repeat: no-repeat, no-repeat;
      background-position: 50% 50%, 50% 50%;
      background-size: cover, cover;
    }

    &-inner {
      position: relative;
    }

    &-supertitle {
      margin-bottom: $sub;
    }

    &-title {
      @include font-size(34px, 1.75);
      @include center(1200px);
      margin-bottom: $sub;

      @include query(sm) {
        @include font-size(48px, 2);
      }

      @include query(md) {
        @include font-size(60px, 3);
      }

      @include query(lg) {
        @include font-size(80px, 4);
      }
    }

    &-lead {
      @include center(500px);
      color: rgba(#fff, .7);
    }

    &-meta {
      margin-top: $sub;

      &, a {
        color: rgba(#fff, .7);
      }

      a {
        &:hover,
        &:focus {
          color: #fff;
        }
      }
    }
  }
}
