$twitter-color: #4b9ad2;

&-twitter {
  padding-top: $sub;
  padding-bottom: 0;
  background-color: $twitter-color;
  color: #fff;

  .section {
    &-icon {
      margin-bottom: $suh;
    }

    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: #fff;
      }
    }
  }

  $arrow-width: $sub * 2;

  .tweets {
    padding-bottom: $arrow-width/2;
  }

  .tweet {
    $bg-color: mix(#fff, $twitter-color, 20%);

    @include font-size(18px);
    position: relative;
    padding: $suh;
    margin-bottom: $sub;
    background-color: $bg-color;
    border-radius: 6px;
    font-family: $font-light;

    @include query(sm) {
      margin-bottom: 0;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      bottom: - $arrow-width;
      left: 50%;
      margin-left: $arrow-width/-2;
      border: $arrow-width/2 solid transparent;
      border-top-color: $bg-color;

      @include query(sm) {
        display: inline-block;
      }
    }
  }

  time {
    @include font-size(14px);
  }

  blockquote {
    border-style: none;
    font-family: $font-light;

    a {
      font-family: $font-regular;
      color: #fff;
    }
  }

  .carousel {
    padding-bottom: $sub * 4;

    @include query(sm) {
      padding-bottom: $sub * 5;
    }

    &-indicators {
      li {
        &.active {
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
