&-key {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #5da147;
  color: rgba(#fff, .75);
  overflow-x: hidden;

  .container-fluid {
    width: auto;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  .section {
    $border: 1px solid rgba(#fff, .25);

    &-point {
      padding-top: $sub * 2;
      padding-bottom: $sub;
      border-bottom: $border;

      @include query(sm) {
        height: $sub * 18;
        padding-top: $sub * 4;
        padding-bottom: $sub * 2;

        &:nth-child(n+3) {
          padding-top: $sub * 3;
        }

        &:nth-child(3) {
          border-bottom-style: none;
        }
      }

      &:last-child {
        border-bottom-style: none;
      }

      &:nth-child(2n) {
        @include query(sm) {
          border-left: $border;
        }
      }

      &-inner {
        @include center(300px);
      }

      &-number {
        display: inline-block;
        position: absolute;
        top: $sub;
        left: $sub;
        color: #aadd99;
      }

      &-image {
        margin-bottom: $suh;
      }

      &-title {
        color: #fff;
      }
    }
  }
}
