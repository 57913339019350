&-featured {
  background-color: #000;

  .section {
    &-overlay {
      padding-bottom: $sub * 5;
      background-image: linear-gradient(rgba(#000, 0) 60%, rgba(#000, .75));

      @include query(sm) {
        padding-top: $sub * 5;
        padding-bottom: $sub * 2;
      }
    }

    &-inner {
      height: $sub * 21;

      @include query(568px) {
        height: $sub * 17.5;
      }

      @include query(sm) {
        height: $sub * 15;
      }

      @include query(md) {
        height: $sub * 17;
      }

      @include query(lg) {
        height: $sub * 20;
      }
    }

    &-title {
      a {
        color: #fff;
      }
    }

    &-lead {
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 5;
      }
    }

    &-author {
      margin-bottom: $sub;

      @include query(sm) {
        float: left;
        margin-bottom: 0;
      }
    }

    &-meta {
      @include query(sm) {
        float: right;
      }
    }

    &-footer {
      @include query(sm) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .person {
    display: inline-block;

    @include query(sm) {
      text-align: left;

      &-avatar {
        float: left;
        margin-right: $suh;
        margin-top: 3px;
        margin-bottom: 0;
      }

      &-about {
        overflow: hidden;
      }

      &-title {
        font-family: $font-medium;
      }
    }
  }

  time {
    display: inline-block;
    margin-right: $sub;
  }

  .carousel {
    &-indicators {
      width: 300px;
      margin-left: -150px;
    }
  }
}
