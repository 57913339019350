.press {
  p {
    margin-bottom: $suh;

    @include query(sm) {
      margin-bottom: $sub;
    }
  }

  &-sizer,
  .article {
    width: (100%/1);

    @include query(568px) {
      width: (100%/2);
    }

    @include query(md) {
      width: (100%/3);
    }
  }

  &-source {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #000;
    text-align: center;
    color: #fff;

    &-title {
      @include font-size(24px);
      font-family: $font-medium;
    }

    &-logo + &-title {
      @extend .sr-only;
    }
  }

  $colors: (
    techcrunch: #009f00,
    theverge: #f24d25,
    forbes: #025293,
    psfk: #582c84,
    giga: #5b8993,
    ifunde: #0678E4,
    gizmodo: #28ADE6,
    ic: #b3272d,
    ninetofivemac: #0D4D7C,
    parislemon: #FAB817,
    bgr: #0080FF
  );

  @each $item, $color in $colors {
    &-#{$item} {
      .press-source {
        background-color: $color;
        color: #fff;
      }

      .article-title {
        color: $color;
      }

      .btn {
        color: $color;

        &:hover,
        &:focus {
          color: adjust-color($color, $lightness: -10%);
        }
      }
    }
  }
}
