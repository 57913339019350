.separator {
  $size: 50px;

  position: relative;
  width: $size;
  height: $size;
  margin: $sub*2 auto;

  .fa {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: $size;
  }

  &-dots {
    color: $brand-primary;
  }

  &-border {
    color: rgba(#fff, .35);
  }
}
