@mixin post-dark {
  background-color: #1a1a1a;
  color: lighten(#000, 30%);

  .post-short {
    &-category {
      a {
        &:hover,
        &:focus {
          color: darken(#fff, 30%);
        }
      }
    }

    &-title {
      color: $brand-primary;

      a {
        &:hover,
        &:focus {
          color: lighten($brand-primary, 20%);
        }
      }
    }

    &-category,
    &-footer {
      color: inherit;
    }

    &-footer {
      a {
        &:hover,
        &:focus {
          color: darken(#fff, 20%);
        }
      }
    }
  }
}
