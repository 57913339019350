&-press {
  background-color: #58a247;
  color: #fff;

  @include query(sm) {
    padding-top: $sub * 3;
    padding-bottom: $sub * 3;
  }

  @include query(md) {
    padding-top: $sub * 4;
    padding-bottom: $sub * 4;
  }

  p {
    color: #000;
  }

  .section {
    &-title {
      @include center(800px);
      margin-bottom: $sub * 2;
      color: #000;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }
    }

    &-point {
      &-number {
        @include center($sub * 2);
        @include font-size(20px);
        margin-bottom: $suh;
        padding-bottom: $suh/2;
        border-bottom: 1px solid rgba(#fff, .2);
        color: rgba(#fff, .6);
      }

      &-title {
        @include font-size(22px);
      }
    }
  }
}
