$icons: (
  paper: 38 48,
  smartphone: 31 54,
  project-manager: 54 39,
  location: 62 50
);

.sprite {
  &-icon {
    display: inline-block;
    background-repeat: no-repeat;

    @each $icon, $dimensions in $icons {
      &-#{$icon} {
        width: nth($dimensions, 1) * 1px;
        height: nth($dimensions, 2) * 1px;
        background-image: url(../images/#{$icon}@2x.png?sprite=keyicons);
        background-size: nth($dimensions, 1) * 1px;
      }
    }
  }
}
