&-contact {
  .section-contact {
    padding-top: $sub * 4;
    padding-bottom: 0;

    @include query(sm) {
      padding-top: $sub * 5;
    }

    @include query(lg) {
      padding-top: $sub * 7;
    }
  }

  h1 {
    @include font-size(32px, 2);

    @include query(xs) {
      @include font-size(48px, 3);
    }

    @include query(sm) {
      @include font-size(64px, 3);
    }

    @include query(md) {
      @include font-size(84px, 3);
    }

    @include query(lg) {
      @include font-size(120px, 5);
    }
  }
}
