&-solution {
  .section {
    &-title {
      @include center(800px);
    }

    &-lead {
      max-width: 600px;
      margin-bottom: $sub * 2;

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }
  }
}
