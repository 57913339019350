&-challenge {
  position: relative;
  padding-bottom: 0;
  background-color: #f0f0f0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $sub * 3;
    background-image: linear-gradient(to bottom, rgba(#000, 0.0), rgba(#000, 0.05));
    pointer-events: none;
  }

  .section {
    &-title {
      @include center(860px);
    }

    &-lead {
      max-width: 600px;
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 4;
      }
    }
  }

  .img {
    &-outer {
      @include center(642px);
    }

    &-inner {
      padding-bottom: 60%;
    }
  }
}
