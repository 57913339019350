.spinner {
  display: inline-block;
  opacity: 0.35;
  font-size: 64px;
  animation: spin 1s linear 0s infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
