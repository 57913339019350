&-features {
  $brown: #ce7729;
  padding-bottom: 0;
  background-color: #f0f0f0;

  .section {
    &-title {
      @include center(700px);
    }

    &-lead {
      @include center(500px);
      margin-bottom: $sub * 2;

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }
  }
}
