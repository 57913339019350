.review {
  $bg-color: #202020;

  &-bubble {
    position: relative;
    margin-bottom: $sub * 2;
    padding: ($sub * 1.5) $sub;
    background-color: $bg-color;
    border-radius: 8px;

    &::after {
      $size: $sub;

      content: "";
      display: block;
      position: absolute;
      bottom: $size * -2;
      left: 50%;
      margin-left: $size / -1;
      border: $size solid transparent;
      border-top: $size solid $bg-color;
    }
  }

  &-title {
    @include font-size(20px);
    font-family: $font-regular;
    color: #fff;

    @include query(sm) {
      position: absolute;
      top: $sub * 2;
      left: 0;
      right: 0;
    }
  }

  &-text {
    @include font-size(18px);
    border-left: none;
    color: rgba(#fff, .5);
  }

  &-button {
    background-color: transparent;
    border-color: rgba(#fff, .1);
    color: $brand-primary;
    transition: all .2s;

    &:hover,
    &:focus {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: #fff;
    }

    &-container {
      @include query(sm) {
        position: absolute;
        bottom: $sub * 2;
        left: 0;
        right: 0;
      }
    }
  }
}
