&-what {
  padding-bottom: 0;

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-title {
      @include center(800px);
      color: $brand-primary;
    }

    &-lead {
      @include center(560px);
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }

    $border: 1px solid darken(#fff, 10%);

    &-point {
      padding: $sub;
      padding-top: $sub * 2;
      border-bottom: $border;

      @include query(sm) {
        height: $sub * 12;
        padding: $sub * 2;
      }

      @include query(md) {
        height: $sub * 15;
        padding: $sub * 3;
      }

      @include query(lg) {
        height: $sub * 13;
        padding-top: $sub * 2.5;
      }

      &:nth-child(2n+1) {
        @include query(sm) {
          border-right: $border
        }
      }

      &:last-child {
        @include query(sm) {
          border-left: $border;
        }

        @include query(md) {
          border-left-style: none;
        }
      }

      @include query(md) {
        border-right: 1px solid darken(#fff, 10%);

        &:nth-child(3n) {
          border-right-style: none;
        }
      }

      &-number {
        position: absolute;
        top: $sub;
        left: $sub;
        display: inline-block;
        color: darken(#fff, 30%);
      }

      &-image {
        margin-bottom: $suh;
      }

      &-title {
        color: $brand-primary;
      }
    }
  }
}
