&-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  @if $off-canvas-overlay == false {
    display: none;
  }

  @include off-canvas-breakpoint {
    display: none;
  }
}
