&-intro {
  padding-top: $sub * 4;
  background-color: #132344;
  color: #fff;

  @include query(sm) {
    padding-top: $sub * 5;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }
  }

  .team {
    &-member {
      display: block;
      padding: $suh;
      border-radius: 5px;
      background-color: rgba(#000, .2);
      color: #d1d1d1;
      text-decoration: none;

      @include query(sm) {
        height: $sub * 21;
        padding: ($sub * 1.5) $sub;
      }

      @include query(md) {
        height: $sub * 18;
      }

      @include query(lg) {
        height: $sub * 17.25;
      }

      a {
        &:hover,
        &:focus {
          color: tint($brand-primary, 15%);
        }
      }

      &-name {
        color: $brand-primary;

        small {
          color: #9c9c9c;
        }
      }
    }
  }

  a.team-member {
    &:hover,
    &:focus {
      background-color: rgba(#fff, .1);

      .team-member-description {
        color: #fff;
      }
    }
  }
}
