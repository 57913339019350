&-yogabuddy {
  h2 {
    color: #f02e47;

    br {
      display: none;

      @include query(sm) {
        display: inline;
      }
    }
  }

  h3 {
    font-family: $font-light;
  }

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: #f02e47;
      }
    }
  }

  .navbar {
    @include skrollr {
      li.active {
        .navbar-nav-link {
          &,
          &:hover,
          &:focus {
            color: #fff;
          }
        }

        .navbar-border {
          background-color: #fff;
        }
      }
    }
  }
}
