.post {
  @include font-size(18px, 1.25);

  &-header {
    margin-bottom: $sub;
    padding-top: $suh * 1.25;
    padding-bottom: $suh * 1.25;
    border-bottom: 1px solid darken(#fff, 15%);

    @include query(sm) {
      margin-bottom: $sub * 2;
      padding-top: $sub;
      padding-bottom: $sub;
    }

    @include query(md) {
      margin-bottom: $sub * 3;
    }
  }

  // alignment fix
  &-author,
  &-date,
  &-share &-share-label {
    margin-top: 3px;
  }

  &-author {
    .person {
      text-align: left;

      &-avatar {
        float: left;
        margin-top: -5px;
        margin-right: $suh;
        margin-bottom: 0;
      }
    }
  }

  &-date {
    display: block;
    text-align: right;
    color: darken(#fff, 40%);

    @include query(sm) {
      text-align: left;
    }
  }

  &-share {
    margin: $suh $grid-gutter-width/-2 0;
    padding: $suh $grid-gutter-width/2 0;
    border-top: 1px solid darken(#fff, 15%);
    text-align: center;

    @include query(sm) {
      margin: 0;
      padding: 0;
      border-style: none;
      text-align: inherit;
    }

    &-label,
    &-networks,
    &-network {
      display: inline-block;
    }

    &-label {
      float: left;
      font-family: $font-light;

      @include query(sm) {
        margin-right: $suh;
      }
    }

    &-networks {
      float: right;
      margin-bottom: 0;

      @include query(sm) {
        float: none;
      }
    }

    a {
      &:hover,
      &:focus {
        img {
          filter: brightness(0.8);
        }
      }
    }

    &-network {
      @each $network, $color in $network-colors {
        &.#{$network} {
          a {
            color: $color;

            &:hover,
            &:focus {
              color: adjust-color($color, $lightness: -10%);
            }
          }
        }
      }
    }
  }

  &-body {
    padding-bottom: $sub;
    color: #535353;

    @include query(sm) {
      padding-bottom: $sub * 2;
    }

    @include query(md) {
      padding-bottom: $sub * 3;
    }

    > * {
      margin-bottom: $sub;
    }

    // grid
    > *,
    > ol,
    > ul {
      @include make-sm-column(9);
      @include make-sm-column-offset(3);

      @include make-md-column(6);
      @include make-md-column-offset(3);

      @include make-lg-column(7);
      @include make-lg-column-offset(2);

      &:last-child {
        margin-bottom: 0;
      }
    }

    > p:first-of-type,
    > .post-length,
    > figure,
    > .wp-caption,
    > img:first-child {
      margin-left: 0;
    }

    > p:first-of-type {
      @include make-sm-column(9);
      @include make-md-column(6);
      @include make-lg-column(7);

      @include make-sm-column-offset(3);
      @include make-md-column-offset(3);
      @include make-lg-column-offset(2);
    }

    .post-length {
      @include make-sm-column(3);
      @include make-md-column(3);
      @include make-lg-column(2);

      margin-top: $suh/2;
      margin-bottom: $suh;
      color: darken(#fff, 30%);

      + p {
        @include make-sm-column-offset(0);
        @include make-md-column-offset(0);
        @include make-lg-column-offset(0);
      }
    }

    figure,
    .wp-caption {
      @include clearfix;
      width: 100% !important;
      clear: both;

      > a,
      > img {
        @include make-sm-column(9);
        display: block;

        &:last-child {
          @include make-sm-column-pull(3);
        }
      }

      figcaption,
      .wp-caption-text {
        @include make-sm-column(3);
      }

      > figcaption,
      > .wp-caption-text {
        &:first-child {
          @include make-sm-column-push(9);
        }
      }
    }

    // cover photo
    > img:first-child {
      width: 100%;
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }

    // lead
    > p:first-of-type {
      @include font-size(22px, 1.25);
      margin-bottom: $sub;
      font-family: $font-light;
      color: $brand-primary;

      @include query(sm) {
        @include font-size(28px, 1.75);
        margin-bottom: $sub * 2;
      }
    }

    h2 {
      @include font-size(28px, 1.75);
      margin-bottom: $sub;
      font-family: $font-regular;
      color: $brand-primary;

      @include query(xs) {
        @include font-size(34px, 1.75);
      }

      @include query(sm) {
        @include font-size(40px, 2);
      }

      strong {
        font-family: $font-regular;
      }
    }

    h3 {
      @include query(sm) {
        @include font-size(32px, 2);
      }

      strong {
        font-family: $font-light;
      }
    }

    ul { @extend .list-romb; }
    dl { @extend .list-enumerate; }

    blockquote,
    ul,
    ol,
    dl,
    dd {
      padding-left: 50px;
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }

    dl {
      @include query(sm) {
        margin-top: $sub * 2;
      }
    }

    dt {
      @include font-size(22px);
      margin-bottom: $sub;
      color: #000;
      font-family: $font-light;
    }

    dd {
      &:last-child {
        margin-bottom: 0;
      }
    }

    blockquote {
      @include font-size(18px);
      border-left-color: $brand-primary;
      color: #808080;

      img {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: $suh;
        border-radius: 50%;
      }

      b {
        @include font-size(14px);
        color: #000;
        font-family: $font-regular;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100%;
      &.extended {
        width:140%;
        max-width:140%;
        margin-left:-20%;
      }
      &.small {
        width:50%;
        max-width:50%;
        margin-left:auto;
        margin-right:auto;
      }
      &.full {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
      &.original {
        width:auto;
        max-width:auto;
        margin-left:auto;
        margin-right:auto;
      }
    }

    form {
      @extend .form;

      br {
        display: none;
      }

      p {
        margin-bottom: 0;
      }
    }

    label {
      font-family: $font-text;
    }

    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
      @extend .form-control;
      @include form-control-focus($brand-primary);
      margin-bottom: 0.5em;

      &:focus {
        border-color: $brand-primary;
      }
    }

    input[type="submit"],
    input[type="reset"],
    button {
      @extend .btn;
      @extend .btn-primary;
    }

    figure,
    .wp-caption {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }

    figcaption,
    .wp-caption-text {
      @include font-size(15px);
      margin-bottom: $sub;
      font-family: $font-regular-italic;

      @include query(sm) {
        margin-bottom: 0;
        padding-top: $sub * 3;
      }

      @include query(md) {
        padding-top: $sub * 6;
      }
    }

    .list-enumerate {
      @include query(sm) {
        position: relative;
        left: $sub * -2.5;
      }
    }

    .video-wrapper {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 1.5;
      }
    }

    .video-container {
      @extend .embed-responsive;
      @extend .embed-responsive-16by9;

      iframe {
        @extend .embed-responsive-item;
      }
    }
  }

  &-comments {
    @include center(800px);
  }

  @import "post-short";
}

@import "post-short/horizontal";
@import "post-short/overlay";
@import "post-short/dark";
@import "post-short/video";
