.next-section {
  margin-top: $sub;

  @include query(sm) {
    margin-top: $sub * 2;
  }

  &-connector {
    width: 1px;
    height: $arrow-size/2;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(#fff, .25);

    @include query(sm) {
      height: $arrow-size;
    }

    &-last {
      height: $arrow-size/2;
    }
  }

  &-anchor {
    $size: 14px;

    margin-bottom: $sub;
    color: #fff;
    line-height: $size;

    .fa {
      position: relative;
      bottom: 2px;
      font-size: $size;
      opacity: .25;
    }
  }

  &-anchor.is-dark {
    color: #000;
  }

  &-anchor.is-dark &-connector {
    background-color: #000;
    background-color: rgba(#000, .25);
  }
}
