&-challenge {
  padding-bottom: 0;

  .section {
    &-title {
      @include center(700px);

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }

    &-lead {
      @include center(600px);
      margin-bottom: $sub * 2;

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }
  }

  .img {
    &-outer {
      max-width: 637px;
    }

    &-inner {
      padding-bottom: 70%;
    }
  }
}
