&-faq {
  padding-bottom: $sub * 2;

  .section {
    &-supertitle {
      margin-bottom: $sub;
    }

    &-title {
      margin-bottom: $sub * 2;
      color: $brand-primary;
    }
  }

  h3 {
    @include font-size(24px, 1.5);
    margin-bottom: $sub * 2;
    color: $brand-primary;

    @include query(sm) {
      @include font-size(45px, 2);
      margin-bottom: $sub * 3;
    }
  }

  .list-enumerate {
    @include center(700px);
    @include font-size(18px);
    color: #777777;
    text-align: left;

    @include query(sm) {
      text-align: left;
    }

    dt {
      @include font-size(22px);
      margin-bottom: $sub;
      color: $brand-primary;

      @include query(sm) {
        &::after {
          content: "Q";
        }
      }
    }
  }

  .separator {
    &-border {
      color: darken(#fff, 25%);
    }
  }
}
