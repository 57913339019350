&-releases {
  background-color: #f2f2f2;

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;
    }
  }

  .border-bottom {
    border-bottom: 1px solid darken(#fff, 15%);
  }

  .container-fluid {
    padding-top: $sub * 2;
    padding-bottom: $sub * 2;
  }

  .release {
    &-date {
      @include font-size(12px);
      color: darken(#fff, 50%);
    }

    &-title {
      @include center(400px);
      @include font-size(24px, 1.5);
      font-family: $font-light;
    }

    .btn {
      background-color: #fff;
      color: $brand-primary;

      &:hover,
      &:focus {
        background-color: $brand-primary;
        color: #fff;
      }
    }
  }
}
