&-intro {
  @include bg-full("bridge.png");
  position: relative;
  padding-top: $sub * 5;
  padding-bottom: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;

  @include query(sm) {
    padding-top: $sub * 7;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }
    }

    &-lead {
      @include center(450px);
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }
    }
  }
}
