&-header {
  .section {
    &-overlay {
      @include query(sm) {
        padding-top: $sub * 5;
      }

      @include query(md) {
        padding-top: $sub * 7;
        padding-bottom: $sub * 4;
      }

      @include query(lg) {
        padding-bottom: $sub * 6;
      }
    }

    &-title {
      max-width: 800px;
      margin-bottom: $sub * 2;

      @include query(md) {
        @include font-size(70px, 3);
        margin-bottom: $sub * 3;
      }
    }
  }

  .tags {
    @include font-size(14px);
    margin-bottom: 0;
    font-family: $font-medium;

    a {
      display: inline-block;
      padding: $suh/3 $sub * .75;
      background-color: rgba(#000, .5);
      border-radius: 4px;
      color: #fff;
      letter-spacing: 2px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: #000;
        text-decoration: none;
      }
    }
  }
}
