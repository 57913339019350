// prefix of your classes, for example .off-canvas-menu
$off-canvas-namespace:            "off-canvas" !default;

// display style of the menu, more styles coming soon...
$off-canvas-style:                normal !default;

// z-index of the menu, sometimes necessary
$off-canvas-zindex:               100 !default;

// position of the menu
$off-canvas-position:             top !default;

// width of the menu, if the position is
// top or bottom, leave this at auto
$off-canvas-width:                auto !default;

// height of the menu, if the position is
// left or right, leave this at auto
$off-canvas-height:               auto !default;

// animation duration of opening the menu
$off-canvas-transition-duration:  .2s !default;

// at which point the menu activates or deactivates
$off-canvas-breakpoint:           to 480px !default;

// whether an overlay will appear,
// which the user can click to close the menu
$off-canvas-overlay:              true !default;
