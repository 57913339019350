$green: #2eb373;

&-intro {
  @include bg-full("watchup/bg.jpg");
  background-color: #dedede;
  border-bottom: 1px solid #d7d7d7;
  color: rgba(#424242, .7);

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-image {
      position: relative;

      @include query(sm) {
        flex: 4;
      }

      &-tablet {
        margin-right: $sub * 2;
        overflow: hidden;

        @include query(sm) {
          margin-right: $sub * 3;
        }

        img {
          max-width: 70%;
          margin-bottom: $sub * -0.5;

          @include query(sm) {
            max-width: 100%;
          }

          @include query(md) {
            margin-bottom: $sub * -1.5;
          }
        }
      }

      &-phone {
        width: 17%;
        max-width: 197px !important;
        position: absolute;
        right: 25%;
        bottom: $sub * -1;

        @include query(xs) {
          right: 15%;
        }

        @include query(sm) {
          width: 30%;
          right: 0;
          bottom: $sub * -2;
        }

        @include query(md) {
          width: 50%;
          bottom: $sub * -4;
        }
      }
    }
  }

  a {
    color: $green;

    &:hover,
    &:focus {
      color: adjust-color($green, $lightness: -15%);
    }
  }

  h3 {
    color: $green;
  }

  .separator {
    &-border {
      color: darken(#fff, 35%);
    }

    &-dots {
      color: #d93939;
    }
  }

  + .section {
    padding-top: $sub * 3;

    @include query(sm) {
      padding-top: $sub * 4;
    }

    @include query(md) {
      padding-top: $sub * 6;
    }
  }
}
