&-about {
  padding-bottom: $sub;

  @include query(sm) {
    padding-bottom: 0;
  }

  .section {
    &-title {
      color: $brand-primary;
    }

    &-lead {
      @include center(640px);
    }
  }

  .separator {
    &-border {
      color: darken(#fff, 25%);
    }

    @include query(sm) {
      margin-top: $sub * 3;
      margin-bottom: $sub * 3;
    }
  }

  .values {
    .section-supertitle {
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      @include query(md) {
        margin-bottom: $sub * 5;
      }
    }

    &-set {
      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }
  }

  .value {
    @include font-size(12px, .8);
    height: 100px;
    margin-bottom: $sub;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #5c5c5c;

    @include query(sm) {
      border-right: 1px solid darken(#fff, 10%);
    }

    &:last-child {
      border-right-style: none;
    }

    &-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $suh;
    }

    &-text {
      display: block;
    }
  }
}
