&-pager {
  padding-top: 0;
  padding-bottom: 0;
  overflow-x: hidden;

  .section {
    &-supertitle {
      @include font-size(10px);
      margin-bottom: $sub;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .container-fluid {
    width: auto;
  }

  .pager {
    padding: $sub ($sub * 2);

    @include query(sm) {
      padding-bottom: $sub * 2;
    }

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: $sub * 2.5;
      border: ($sub) solid transparent;

      @include query(sm) {
        top: $sub * 3;
        border-width: $sub * 1.5;
      }
    }

    &-title {
      @include font-size(22px, 1.25);
      @include center(500px);

      @include query(sm) {
        @include font-size(28px, 1.5);
      }

      a {
        color: #000;

        &:hover,
        &:focus {
          color: $brand-primary;
        }
      }
    }

    &-prev {
      border-bottom: 1px solid darken(#fff, 15%);

      @include query(sm) {
        padding-left: $sub * 3;
        border-right: 1px solid darken(#fff, 15%);
        border-bottom-style: none;
      }

      &::before {
        left: $sub * -0.5;
        border-right-color: darken(#fff, 30%);

        @include query(sm) {
          left: $sub * -1;
        }
      }

      &::after {
        left: ($sub * -0.5) + 1px;
        border-right-color: #fff;

        @include query(sm) {
          left: ($sub * -1) + 1px;
        }
      }
    }

    &-next {
      @include query(sm) {
        padding-right: $sub * 3;
        border-left: 1px solid darken(#fff, 15%);
      }

      &::before {
        right: $sub * -0.5;
        border-left-color: darken(#fff, 30%);

        @include query(sm) {
          right: $sub * -1;
        }
      }

      &::after {
        right: ($sub * -0.5) + 1px;
        border-left-color: #fff;

        @include query(sm) {
          right: ($sub * -1) + 1px;
        }
      }
    }
  }
}
