&-solution {
  overflow: visible;
  padding-bottom: 0;
  background-color: #e6e6e6;

  .section {
    &-title {
      @include center(700px);
    }

    &-lead {
      max-width: 500px;
    }

    &-image {
      position: relative;
      top: $sub * 2;

      img {
        margin-left: 0;
      }
    }
  }
}
