&-tabs {
  padding-top: 0;
  padding-bottom: 0;

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-navigation {
      width: 100%;
      background-color: rgba(#fff, .9);
      border-bottom: 1px solid darken(#fff, 15%);
      z-index: $zindex-navbar-fixed;

      .container-fluid {
        width: auto;
        padding: 0;
      }

      .nav {
        @include font-size(12px);
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 2px;

        a {
          $border-width: 3px;
          $padding-vertical: $suh;
          display: inline-block;
          padding: ($padding-vertical + $border-width) 0 $padding-vertical;
          color: lighten(#000, 35%);

          @include query(sm) {
            $padding-vertical: $sub;
            padding: ($padding-vertical + $border-width) 0 $padding-vertical;
          }

          &,
          &:hover,
          &:focus {
            background-color: transparent;
            border-style: none;
            border-radius: 0;
            border-bottom: $border-width solid transparent;
          }

          &:hover,
          &:focus {
            color: #000;
          }
        }

        .active a {
          color: #000;
          font-family: $font-medium;

          @include query(sm) {
            border-bottom-color: $brand-primary;
          }
        }
      }
    }
  }

  .section {
    &:first-of-type {
      @include query(md) {
        padding-top: $sub * 3;
        padding-bottom: $sub * 3;
      }
    }

    &.is-loading {
      padding: 0;
      padding-top: $sub * 4 !important;
      text-align: center;
    }
  }
}
