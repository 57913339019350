@mixin post-overlay {
  color: rgba(#fff, .9);

  .post-short {
    &-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
    }

    &-inner {
      position: relative;
      height: 100%;
      background-color: rgba(#37303c, .9);
    }

    &-category,
    &-title {
      color: #fff;

      a {
        &:hover,
        &:focus {
          color: lighten($brand-primary, 20%);
        }
      }
    }

    &-footer {
      color: rgba(#fff, .4);

      a {
        &:hover,
        &:focus {
          color: darken(#fff, 20%);
        }
      }
    }
  }
}
