&-questions {
  background-color: #f0f0f0;

  @include query(md) {
    padding-top: $sub * 4;
  }

  .container-fluid {
    width: auto;
    padding: 0;
  }

  .person {
    margin: $suh;

    @include query(sm) {
      margin: $sub;
    }

    @include query(md) {
      margin: $sub $sub * 2;
    }

    &-name {
      font-family: $font-regular;
    }

    &-title {
      color: #ce7729;
    }
  }
}
