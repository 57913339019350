&-thanks {
  @include query(md) {
    padding-top: $sub * 3;
  }

  .section {
    &-title {
      color: $brand-primary;
    }
  }
}
