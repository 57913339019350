&-short {
  padding-top: $sub * 2;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include query(sm) {
    padding-top: $sub * 3;
    padding-bottom: $sub * 2;
    text-align: left;

    &:nth-child(odd) {
      .section-text {
        order: 1;
      }

      .section-image {
        order: 3;
        margin-right: 0;
      }
    }

    &:nth-child(even) {
      .section-text {
        order: 3;
        margin-right: 0;
      }

      .section-image {
        order: 1;
      }
    }
  }

  @include query(md) {
    padding-top: $sub * 4;
  }

  @include query(lg) {
    height: 550px;
    padding-top: $sub * 4;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;

    @include query(sm) {
      flex-direction: row;
    }
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: 0;

        &-border {
          margin-left: 0;
        }
      }
    }

    &-text {
      margin-bottom: $sub * 1.5;

      @include query(sm) {
        flex: 0 0 440px;
        margin-right: $sub * 2;
        margin-bottom: 0;
      }
    }

    &-lead {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      @include query(lg) {
        margin-bottom: $sub * 3;
      }
    }

    &-image {
      position: relative;
      margin-bottom: $sub * -2;

      @include query(sm) {
        flex: 1;
        margin-right: $sub * 2;
        margin-bottom: 0;
      }

      img {
        @media (max-width: $screen-xs-max) {
          max-width: 80%;
          height: auto;
        }

        @include query(sm) {
          // position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .btn {
    @include font-size(14px);
    padding-left: $sub;
    padding-right: $sub;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 2px;

    &[disabled] {
      opacity: 0.4;
    }
  }
}
