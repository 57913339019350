&-reviews {
  padding-top: 0;
  padding-bottom: $sub;
  background-color: #151515;
  color: #fff;

  @include query(sm) {
    padding-bottom: $sub * 3;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .review {
    margin-bottom: $sub;

    @include query(sm) {
      margin-bottom: 0;
    }

    &-bubble {
      @include query(sm) {
        display: table;
        position: relative;
        height: $sub * 16;

        @include query(lg) {
          height: $sub * 14;
        }

        > * {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}
