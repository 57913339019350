&-challenge {
  padding-bottom: 0;

  .section {
    &-lead {
      max-width: 640px;
      margin-bottom: $sub * 2;

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }
  }

  .img {
    &-outer {
      @include center(818px);
    }

    &-inner {
      padding-bottom: 52%;
    }
  }
}
