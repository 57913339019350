&-posts {
  background-color: #e9e9e9;

  .section {
    &-supertitle {
      margin-bottom: $sub * 1.5;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .post {
    &-short {
      &-container {
        @include make-xs-column(12);

        &:nth-child(n) {
          @include make-sm-column(6);

          @include query(sm) {
            .post-short {
              &-inner {
                height: $sub * 16;
              }
            }
          }
        }

        &:nth-child(3n+1) {
          @include make-sm-column(12);

          @include query(sm) {
            .post-short {
              &-inner {
                height: auto;
              }
            }
          }
        }

        &:nth-child(n) {
          @include make-md-column(4);

          @include query(md) {
            .post-short {
              &-inner {
                height: $sub * 12;
              }

              &-body {
                display: none;
              }
            }
          }
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5) {
          @include make-md-column(8);

          @include query(md) {
            .post-short {
              &-body {
                display: block;
              }
            }
          }
        }

        &:nth-child(n) {
          @include make-lg-column(3);

          @include query(lg) {
            .post-short {
              &-inner {
                height: $sub * 13;
              }

              &-body {
                display: none;
              }
            }
          }
        }

        &:first-child,
        &:last-child {
          @include make-lg-column(6);

          @include query(lg) {
            .post-short {
              &-body {
                display: block;
              }
            }
          }
        }

        &:nth-child(3),
        &:nth-child(5) {
          @include query(lg) {
            .post-short {
              @include post-overlay;
              height: $sub * 21;

              &-body {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
