&-menu {
  @include off-canvas-init;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: $off-canvas-width;
  height: $off-canvas-height;

  @if ($off-canvas-style == normal) {
    z-index: $off-canvas-zindex;
  }

  #{opposite($off-canvas-position)}: auto;

  transform: $off-canvas-menu-transform;

  @include off-canvas-breakpoint {
    display: none;
  }
}

&.is-active &-menu {
  transform: translate3d(0,0,0);
}
