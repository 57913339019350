&-via {
  @include query(sm) {
    padding-bottom: $sub * 3;
  }

  @include query(md) {
    padding-bottom: $sub * 4;
  }

  .via-tel {
    color: inherit;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      @include query(sm) {
        margin-bottom: $sub * 4;
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  img {
    margin-bottom: $sub;
  }

  strong {
    @include font-size(12px);
    font-family: $font-regular;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $brand-primary;
  }

  .separator {
    &-border {
      color: darken(#fff, 15%);
    }

    @include query(sm) {
      position: absolute;
      top: $sub * 2.5;
      right: -17px;
      margin: 0;
    }
  }
}
