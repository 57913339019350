&-comments {
  border-bottom: 2px solid darken(#fff, 15%);

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }
}
