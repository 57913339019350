@import "../post-short/horizontal";

& {
  @include query($grid-float-breakpoint) {
    height: $navbar-height;
    transition: height .3s, background-color .3s;
    /* overflow: hidden; */

    &.is-expanded {
      height: 100%;

      @include skrollr {
        background-color: rgba(#000, .75) !important;

        .navbar {
          &-nav-link {
            &,
            &:hover,
            &:focus {
              color: #fff;
            }
          }

          &-border,
          li.active .navbar-border {
            background-color: #fff !important;
          }
        }

        .logo {
          @include logo("light");

          &-blog {
            @include logo("blog-light");
          }
        }
      }

      .navbar-content {
        opacity: 1;
      }
    }
  }

  .by {
    display: block;
    float: left;
    padding-top: 24px;
    padding-bottom: 24px;
    color: rgba(#fff, 0.5);
  }

  .logo-blog {
    width: 105px;
  }

  .navbar-brand {
    padding-left: 0.25em;
    padding-right: 0.25em;

    ~ .navbar-brand {
      margin-left: 0;

      .logo {
        width: 105px;
      }
    }
  }
}

&-nav {
  &-link {
    transition: opacity .3s;

    &.has-content {
      color: #fff;
    }
  }

  li:last-child {
    i {
      position: relative;
      top: 3px;
      display: inline-block;
      width: $suh * 1.5;
      transform: scale(1) rotate(0deg);
      transition: transform .3s;
    }
  }

  > li > .dropdown-menu {
    border-radius: 0;
  }
}

// make room for WordPress navigation bar
&.signed-in { top: 32px; }

.dropdown {
  .navbar-nav-link::after {
    content: "";
    display: inline-block;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -10px;
    opacity: 0;
    transition: opacity .2s;
    pointer-events: none;
  }

  &.open .navbar-nav-link::after {
    opacity: 1;
  }

  &-menu {
    width: 476px;
    padding: 20px;
    border-style: none;

    &-left {
      left: -40px;
    }

    &-right {
      right: -40px;
    }

    .divider {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .post-short-category {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .post-short-title {
      height: $sub * 2;
      overflow: hidden;
    }
  }
}

.post-short {
  @include post-horizontal;
  height: 110px;

  .post-short-image {
    width: 40%;
  }

  .post-short-inner {
    width: 60%;
    padding: 0 10px;
  }

  &-title {
    @include font-size(20px);
  }

  &-body {
    display: none;
  }

  .post-short-footer {
    bottom: 0;
    left: 10px;
    right: 10px;
  }
}
