.platforms {
  margin-top: $sub;
  margin-bottom: $sub;
}

.platform {
  margin-bottom: $suh;

  @include query(sm) {
    margin-bottom: 0;
  }

  &-image {
    @include query(sm) {
      margin-bottom: $suh;
    }
  }
}
