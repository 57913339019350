.social {
  &-network {
    margin-right: $suh/2;

    @each $network, $color in $network-colors {
      &.#{$network} {
        a {
          background-color: $color;

          &:hover,
          &:focus {
            background-color: adjust-color($color, $lightness: -10%);
          }
        }
      }
    }
  }
}
