$font-size-base:             16px !default;
$line-height-computed:       20px !default;
$line-height-base:           $line-height-computed / $font-size-base;

$sub:                        $line-height-computed;
$suh:                        $line-height-computed / 2;

$screen-xs-min:              480px !default;
$screen-sm-min:              768px !default;
$screen-md-min:              992px !default;
$screen-lg-min:              1200px !default;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$network-colors: (
  facebook:    #3b5998,
  twitter:     #00aced,
  google-plus: #da2713,
  vimeo:       #1bb7ea,
  youtube:     #e14d40,
  github:      #000000,
  gmail:       #d72c2c,
  linked-in:   #0274b3,
  instagram:   #2c6e8e,
  vine:        #00a478,
  dribbble:    #df4c85,
  behance:     #000000,
  flickr-blue: #1e65d9,
  flickr-pink: #fc1486,
  soundcloud:  #ff631c,
  feedzilla:   #ca1116,
  feeda:       #fedc32
);
