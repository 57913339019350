@if ($off-canvas-style == normal) {
  &-fixed {
    @include off-canvas-init;
    transform: translate3d(0,0,0);
  }

  &.is-active &-fixed {
    transform: $off-canvas-content-transform;

    @include off-canvas-breakpoint {
      transform: translate3d(0,0,0);
    }
  }
}
