.section {
  padding-top: $sub * 2;
  padding-bottom: $sub * 2;
  overflow: hidden;

  &.has-anchor {
    padding-top: 0;
  }

  &.has-next {
    padding-bottom: 0;
  }

  &-supertitle {
    @include font-size(12px);
    margin-bottom: $suh;
    font-family: $font-medium;
    text-transform: uppercase;
    letter-spacing: 3px;

    &-border {
      display: block;
      width: 45px;
      border-bottom: 1px solid #fff;
      margin-top: $suh;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-lead {
    @include font-size(18px);
    margin-left: auto;
    margin-right: auto;
  }

  blockquote.section-lead {
    @include font-size(20px);
    max-width: 600px;

    small {
      @include font-size(14px);
    }
  }

  @import "sections/intro";

  @import "sections/contact";
  @import "sections/testimonials";
  @import "sections/partners";

  @import "sections/home/all";
  @import "sections/case-study/all";
  @import "sections/work/all";
  @import "sections/how/all";
  @import "sections/privacy/all";

  @import "sections/watchup/all";
  @import "sections/yogabuddy/all";
  @import "sections/doctor/all";
  @import "sections/pic/all";
  @import "sections/corner/all";

  @import "sections/about/all";
  @import "sections/contact/all";

  @import "sections/blog/all";
  @import "sections/landing/all";
}
