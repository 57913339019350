$green: #71a547;

&-testimonials {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #313131;
  color: rgba(#fff, .5);

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;
      color: #fff;

      &-border {
        border-bottom-color: $green;
      }
    }
  }

  .testimonial {
    &-title {
      color: $green;
    }

    &-text {
      border-left-style: none;
    }

    a {
      color: $green;

      &:hover,
      &:focus {
        color: adjust-color($green, $lightness: 10%);
      }
    }
  }

  .carousel {
    padding-bottom: $sub * 4;

    @include query(sm) {
      padding-bottom: $sub * 5;
    }

    &-indicators {
      li {
        &.active {
          span {
            color: $green;
          }
        }
      }
    }

    .item {
      @include query(sm) {
        height: $sub * 20;
      }

      @include query(md) {
        height: $sub * 18;
      }

      @include query(lg) {
        height: $sub * 16;
      }

      .container-fluid,
      .row {
        height: 100%;
      }
    }
  }

  .col-sm-6 {
    &:first-child .testimonial {
      @include query(sm) {
        padding-right: $sub * 2;
      }

      @include query(md) {
        padding-right: $sub * 3;
      }

      @include query(lg) {
        padding-right: $sub * 4;
      }
    }

    &:last-child .testimonial {
      @include query(sm) {
        padding-left: $sub * 2;
      }

      @include query(md) {
        padding-left: $sub * 3;
      }

      @include query(md) {
        padding-left: $sub * 4;
      }
    }
  }

  .separator {
    &-dots {
      color: $green;
    }

    @include query(sm) {
      position: absolute;
      top: $sub * 4;
      right: -17px;
    }
  }

  .person {
    &-name {
      color: #fff;
    }
  }
}
