&-list {
  padding-bottom: 0;
  background-color: #e9e9e9;

  .container-fluid {
    width: auto;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .team {
    margin-bottom: 0;

    &-member {
      $border: 1px solid #dcdcdc;

      height: $sub * 12.25;
      padding: $sub $suh;
      border-bottom: $border;
      color: #818181;
      cursor: pointer;

      @include query(sm) {
        height: $sub * 13.25;
        padding: ($sub * 1.5) $sub;
        border-right: $border;
      }

      &:hover,
      &:focus {
        background-color: #fff;
      }

      &-name {
        color: $brand-primary;
      }

      &-title {
        color: #818181;
      }

      &-company {
        a {
          color: #494949;

          &:hover,
          &:focus {
            color: #000;
          }
        }
      }

      &-social {
        margin-bottom: 0;

        a {
          color: #7d7d7d;
        }

        @each $network, $color in $network-colors {
          &-#{$network} {
            &:hover,
            &:focus {
              color: $color;
            }
          }
        }
      }
    }
  }
}
