@mixin post-video {
  .post-short {
    &-image {
      @extend .fa;
      @extend .fa-play;
      display: block;
      position: relative;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $sub;
        color: #fff;
        font-size: 42px;
        opacity: .75;
        text-shadow: 0 0 30px #000;
      }

      &:hover,
      &:focus {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
