.img-outer {
  @include center;
}

.img-inner {
  position: relative;
  height: 0;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
