&-contact {
  padding-top: 0;
  padding-bottom: $sub;
  background-color: #b00e35;
  color: rgba(#fff, .75);

  @include query(sm) {
    padding-bottom: $sub * 3;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub;
      color: #fff;

      @include query(sm) {
        margin-bottom: $sub * 2;
      }

      &-border {
        border-bottom-color: #fff;
      }
    }

    &-title {
      color: #fff;
    }

    &-lead {
      max-width: 500px;
      margin-bottom: $sub;

      @include query(xs) {
        margin-bottom: $sub * 2;
      }
    }
  }

  .contact {
    margin-bottom: $sub * 2;

    @include query(sm) {
      margin-bottom: $sub * 3;
    }

    @include query(md) {
      margin-bottom: $sub * 4;
    }

    &-field {
      width: 100%;
      padding: $suh/2 $suh;
      background-color: #941931;
      border-style: none;
      border-radius: 4px;
      color: #fff;
      -webkit-font-smoothing: antialiased;

      &::placeholder {
        color: rgba(#fff, .75);
      }

      &:focus {
        border-color: $brand-primary;
      }
    }

    &-label {
      color: rgba(#fff, .75);
    }

    textarea {
      resize: vertical;

      @include query(sm) {
        height: ($sub * 8.5) - 4px;
      }

      @include query(md) {
        height: ($sub * 11) - 2px;
      }
    }

    &-budget {
      @include clearfix;
      height: $input-height-base;

      .dropdown {
        &-label {
          position: absolute;
          left: $suh;
        }

        &-toggle {
          @include form-control-focus();
          position: relative;
          padding-left: $sub * 3;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

          i {
            position: absolute;
            top: 9px;
            right: $suh;
            color: $brand-primary;
          }
        }

        &-menu {
          width: 100%;
        }
      }
    }

    &-target-devices {
      padding-bottom: $suh;

      .checkbox {
        margin: 0;
        margin-bottom: $suh/2;

        &:first-child {
          margin-top: $suh;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn-primary {
      @include font-size(12px);
      background-color: #2a2a2a;
      border-color: transparent;
      text-transform: uppercase;
      letter-spacing: 3px;
      transition: all .2s;

      &:hover,
      &:focus {
        background-color: darken(#2a2a2a, 5%);
      }
    }
  }

  address {
    @include font-size(14px);
    color: rgba(#fff, .75);

    a {
      color: #fff;
    }

    strong {
      @include font-size(12px);
      display: inline-block;
      color: #fff;
      font-family: $font-medium;
      text-transform: uppercase;
      letter-spacing: 2px;

      @include query(sm) {
        margin-bottom: $suh;
      }
    }
  }

  .separator {
    &-border {
      color: rgba(#fff, .3);
    }

    &-dots {
      color: #000;
    }
  }

  .addresses {
    .separator {
      @include query(sm) {
        position: absolute;
        top: $suh - 17px;
        right: -17px;
      }
    }

    &-tel {
      color: #FFF;
    }
  }
}
