&-achievments {
  padding-top: $sub * 2;
  color: #777;
  overflow: visible;

  .section {
    &-supertitle {
      color: #000;
    }

    &-title {
      @include center(700px);
    }

    &-lead {
      max-width: 500px;
      margin-bottom: $sub * 2;
      color: #000;

      @include query(sm) {
        margin-bottom: $sub * 4;
      }
    }

    &-points {
      margin-bottom: $sub;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      img,
      h3 {
        margin-bottom: $suh;
      }

      h3 {
        color: #000;
      }

      [class*="col-"] {
        padding-left: $sub * 1.5;
        padding-right: $sub * 1.5;
      }
    }
  }

  h3 {
    font-family: $font-light;
  }
}
