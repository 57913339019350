&-how {
  padding-top: 0;
  background-color: #1a89d0;
  color: #fff;
  overflow: hidden;

  .no-touch & {
    padding-bottom: 0;
  }

  .hexagons {
    &-idea,
    &-device {
      display: none;
    }

    .no-touch & {
      position: relative;
      height: 717px;
      margin-bottom: $sub * -2;

      &-all {
        display: none;
      }

      &-idea,
      &-device {
        display: block;
      }

      &-device,
      &-idea {
        position: absolute;
        top: 0;
        left: 50%;
      }

      &-device,
      &-all {
        margin-left: -561px;
      }

      &-idea {
        top: 117px;
        margin-left: -306px;
        transform-origin: 50% 100%;
      }
    }
  }

  .section {
    &-lead {
      max-width: 480px;
    }

    &-title {
      br {
        display: none;

        @include query(xs) {
          display: inline;
        }
      }
    }
  }
}
