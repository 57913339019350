&-short {
  position: relative;
  background-color: #fff;
  color: #666;
  text-align: left;

  &-list {
    @include make-row;

    @include query(sm) {
      padding-left: $grid-gutter-width/4;
      padding-right: $grid-gutter-width/4;
    }

    @include query(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-container {
    margin-bottom: $sub;

    @include query(sm) {
      padding-left: $grid-gutter-width/4;
      padding-right: $grid-gutter-width/4;
      margin-bottom: $grid-gutter-width;
    }

    @include query(lg) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }

  &-image {
    display: block;
    height: $sub * 8;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  &-inner {
    padding: $suh;

    @include query(sm) {
      padding: $sub;
      padding-bottom: $sub * 3;
    }
  }

  &-category,
  &-title {
    a {
      max-width: 100%; // IE 8
      color: inherit;

      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }

  &-category {
    @include font-size(12px);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #604776;
  }

  &-title {
    @include font-size(22px, 1.25);
    margin-bottom: $sub;
    color: #000;

    a {
      display: inline-block;
    }

    @include query(sm) {
      @include font-size(28px, 1.5);
    }
  }

  &-footer {
    color: rgba(#000, .4);

    a {
      color: inherit;

      &:hover,
      &:focus {
        color: darken(#fff, 60%);
      }
    }

    @include query(sm) {
      position: absolute;
      right: $sub;
      bottom: $sub;
      left: $sub;
    }
  }
}
