&-landing {
  @include font-size(18px);
  background-color: rgba(#fff, .9);
  border-color: #fff;
}

&-header {
  &::after {
    clear: none;
  }
}

&-brand {
  position: relative;
  bottom: 4px;
}

&-text {
  text-align: right;
}

&-tel {
  display: inline-block;
  margin-left: $suh/2;
  padding: 0 $suh/2;
  background-color: $brand-primary;

  &,
  &:hover,
  &:focus {
    color: #fff;
  }
}
