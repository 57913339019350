.navbar {
  @include font-size(16px);
  border-bottom-style: none;
  background-color: rgba(#000, .75);

  a {
    position: relative;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &-brand {
    position: relative;
    bottom: 4px;
  }

  & &-toggle {
    border-style: none;

    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &-btn {
    @include font-size(16px);
    position: relative;
    top: 1px;
  }

  &-collapse {
    max-height: $sub * 16;
  }

  &-nav {
    text-align: center;
  }

  @include query($grid-float-breakpoint) {
    &-btn {
      margin-left: $sub;
    }

    &-border {
      $bottom: $sub - 3px;

      display: block;
      height: 2px;
      position: absolute;
      // based on the widest navigation link
      left: $sub * 4;
      right: $sub * 4;
      bottom: $bottom;
      background-color: #fff;
      background-color: #fff;
      transition: all .2s;

      a:hover &,
      a:focus &,
      li.active & {
        left: $navbar-padding-horizontal;
        right: $navbar-padding-horizontal;
      }

      li.active & {
        height: 3px;
        background-color: #fff;
        bottom: $bottom - 1px;
      }
    }
  }

  @import "navigation/all";
}
