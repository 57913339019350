&-find {
  padding-top: $sub * 4;
  background-color: #000;
  text-align: left;
  color: #fff;

  .section {
    &-title {
      @include query(lg) {
        @include font-size(96px, 3.5)
      }

      span {
        display: inline-block;
      }
    }

    &-label,
    &-field {
      height: auto;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border-style: none;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }

    &-label {
      padding-right: $suh;
      color: #434343;

      @include query(sm) {
        padding-right: $sub;
      }
    }

    &-field {
      color: $brand-primary;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .input-group {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
}
