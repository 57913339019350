&-subscribe {
  #gform_1 {
    @include make-row;
  }

  .gform_fields {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .gfield_label {
    @extend .sr-only;
  }

  .gform_body {
    @include make-sm-column(8);
    @include make-md-column(6);
    @include make-md-column-offset(2);
  }

  .gform_footer {
    @include make-sm-column(4);
    @include make-md-column(2);
  }

  #input_1_2 {
    @extend .form-control;
    @extend .input-lg;
    margin-bottom: $suh;
    border-width: 2px;

    @include query(sm) {
      margin-bottom: 0;
    }

    &::placeholder {
      color: darken(#fff, 30%);
    }

    &:focus {
      border-color: $brand-primary;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($brand-primary, 0.6);
    }
  }

  #field_1_3 {
    display: none;
  }

  #gform_submit_button_1 {
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-block;
    @extend .btn-primary;
  }
}
