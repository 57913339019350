.contact-box {
  padding: $sub;
  background-color: #f2f2f2;

  @include query(sm) {
    padding: $sub * 2;
  }

  .form {
    &-group {
      margin-bottom: $suh/2;
    }

    &-control {
      border-style: none;
      -webkit-font-smoothing: antialiased;

      &:focus {
        border-color: $brand-primary;
      }

      &::placeholder {
        color: #5c5c5c;
      }
    }

    .-placeholder {
      color: #5c5c5c;
    }
  }

  .dropdown {
    text-align: left;

    &-toggle {
      position: relative;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &-value {
        color: #000;

        &.is-placeholder {
          color: #5c5c5c;
        }
      }

      i {
        position: absolute;
        top: 11px;
        right: $suh;
        color: $brand-primary;
      }
    }

    &-menu {
      width: 100%;
    }
  }

  .btn-primary {
    padding: $sub;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
  }
}
