&-locations {
  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }

    &-image {
      $width: 897px;
      $height: 528px;

      @include center($width);
      position: relative;
      height: $height;
      margin-bottom: $sub * 2;

      @include hidpi {
        img {
          width: $width;
          height: $height;
        }
      }

      @include query(sm) {
        margin-bottom: $sub * 3;
      }

      &-container {
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .fa {
          position: absolute;
          top: 50%;
          right: $sub;
          font-size: 64px;
          color: rgba(#000, .2);
          text-decoration: none;
        }
      }

      $bg-color: #b13351;
      .popover {
        @include font-size(14px);
        width: 200px;
        text-align: center;
        background-color: $bg-color;
        box-shadow: none;
        border-style: none;
        border-radius: 2px;
        color: #fff;

        &-title {
          @include font-size(12px);
          padding-top: $sub * .75;
          padding-bottom: $sub * .75;
          border-style: none;
          border-radius: 0;
          background-color: transparent;
          font-family: $font-regular;
          text-transform: uppercase;
          letter-spacing: 4px;
        }

        &-content {
          padding-top: 0;
          padding-bottom: 0;
        }

        .arrow {
          border-color: transparent;

          &::after {
            border-top-color: $bg-color;
          }
        }
      }
    }
  }

  .location {
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-block;
    padding: $suh;

    &::after {
      content: "";
      display: block;
      width: $suh/2;
      height: $suh/2;
      background-color: $brand-primary;
      border-radius: 50%;
    }

    &-san-francisco {
      $bg-color: #ffe26d;
      + .popover {
        height: 130px;
        background-color: $bg-color;
        color: rgba(#000, .7);

        .popover-title {
          padding-top: $suh;
          padding-bottom: $suh/4;
          color: #000;

          &::after {
            @include center(40px);
            content: "";
            display: block;
            margin-top: $suh;
            border-top: 2px solid $brand-primary;
          }
        }

        .popover-content {
          padding-top: 9px;
          padding-bottom: 9px;
        }

        .arrow::after {
          border-top-color: $bg-color;
        }
      }
    }

    &-san-francisco { top: 228px; left: 90px;  }

    &-athens        { top: 226px; left: 476px; }
    &-bangalore     { top: 280px; left: 620px; }
    &-belgrade      { top: 212px; left: 474px; }
    &-bucharest     { top: 200px; left: 476px; }
    &-buenos-aires  { top: 430px; left: 270px; }
    &-gdynia        { top: 172px; left: 464px; }
    &-granada       { top: 224px; left: 404px; }
    &-helsinki      { top: 142px; left: 462px; }
    &-hong-kong     { top: 250px; left: 740px; }
    &-katowice      { top: 190px; left: 456px; }
    &-kiev          { top: 182px; left: 488px; }
    &-lima          { top: 370px; left: 204px; }
    &-london        { top: 175px; left: 398px; }
    &-new-delhi     { top: 256px; left: 624px; }
    &-omsk          { top: 146px; left: 606px; }
    &-rome          { top: 222px; left: 452px; }
    &-solin         { top: 216px; left: 464px; }
    &-stockholm     { top: 152px; left: 450px; }
    &-tel-aviv      { top: 240px; left: 504px; }
    &-tomsk         { top: 136px; left: 634px; }
    &-warsaw        { top: 184px; left: 466px; }
    &-zagreb        { top: 206px; left: 464px; }

    &-rome,
    &-gdynia,
    &-katowice,
    &-warsaw,
    &-solin,
    &-zagreb,
    &-belgrade {
      padding: $suh/2;
    }
  }

  .locations {
    @include font-size(12px);
    margin-bottom: 0;
    color: darken(#fff, 40%);
    text-transform: uppercase;
    letter-spacing: 2px;

    li {
      display: inline-block;

      &::after {
        content: "\00B7";
        display: inline-block;
        margin: 0 $suh;
        color: $brand-primary;

        @include query(lg) {
          margin-left: $sub;
          margin-right: $sub;
        }
      }

      &:last-child::after {
        content: normal;
      }
    }
  }
}
