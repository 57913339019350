&-content {
  position: relative;
}

@if ($off-canvas-style == normal) {
  &-content {
    @include off-canvas-init;
  }

  &.is-active &-content {
    transform: $off-canvas-content-transform;

    @include off-canvas-breakpoint {
      transform: translate3d(0,0,0);
    }
  }
}
