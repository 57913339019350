&-technology {
  @include background-image('technology-mobile', 'png', 600px auto);
  padding-bottom: 0;
  background-color: #006bb0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;

  @include query(sm) {
    @include background-image('technology', 'png', 2553px 893px);
    background-position: 65% 0%;
  }

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;
    }
  }

  .list-unstyled {
    margin-bottom: $sub * 1.5;
    color: rgba(#fff, .75);

    li {
      margin-bottom: $suh;
    }
  }

  .next {
    margin-top: $sub;
  }
}
