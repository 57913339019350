&-authors {
  padding-bottom: 0;

  .section {
    &-supertitle {
      margin-bottom: $sub * 2;

      &-border {
        border-bottom-color: $brand-primary;
      }
    }
  }

  .carousel {
    margin-bottom: 0;
    padding-bottom: $sub * 2;

    @include query(sm) {
      padding-bottom: $sub * 3;
    }

    &-indicators {
      margin-bottom: 0;

      li {
        span {
          color: lighten(#000, 30%);
        }

        &.active {
          span {
            color: $brand-primary;
          }
        }
      }
    }
  }

  .team {
    color: #818181;

    &-member {
      margin-bottom: $sub * 2;

      @include query(sm) {
        height: $sub * 9;
        margin-bottom: $sub;
      }

      &-name {
        margin-bottom: $suh;
        color: $brand-primary;

        small {
          color: #818181;
        }
      }
    }
  }
}
