&-corner {
  .section {
    &-supertitle {
      &-border {
        border-bottom-color: #e75e47;
      }
    }
    
    &-title {
      color: #e75e47;
    }
  }
}
