.questions {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: $sub * 2;
  overflow: hidden;
}

.question {
  $arrow-width: $sub * 1.5;
  $brown: #ce7729;

  display: flex;
  flex: 0 0 340px;
  align-content: center;
  position: relative;
  margin-right: $sub;
  padding: $suh;
  background-color: #fff;
  color: #9a9a9a;
  text-align: left;

  @include query(sm) {
    padding: $suh * 1.5;
  }

  @include query(md) {
    margin-right: $sub * 2;
  }

  @include query(lg) {
    padding: $sub;
  }

  &:last-child {
    margin-right: 0;
  }

  &::before {
    content: "Q";
    display: block;
    flex: 0 0 1.25em;
    padding-top: $suh;
    font-size: 36px;
    color: $brown;
    font-family: $font-regular;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -$arrow-width;
    left: $sub;
    border: $arrow-width/2 solid transparent;
    border-top-color: #fff;
  }

  p {
    margin-bottom: $suh;

    @include query(md) {
      margin-bottom: $sub;
    }
  }

  a {
    @include font-size(15px);
    color: $brown;

    &:hover,
    &:focus {
      color: mix(#000, $brown, 15%);
    }
  }

  &-text {
    @include font-size(24px, 1.25);
    flex: 1;
    margin-bottom: 0;
    font-family: $font-light;
  }

  &-link {
    display: inline-block;
    margin-right: $sub * .75;
  }
}
