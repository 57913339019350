&-solution {
  background-image: url("/images/corner/panel-bg@2x.jpg");
  background-repeat: no-repeat;
  background-size: 845px 723px;
  background-position: 100% 160%;
  background-color: #f0f0f0;
  overflow: visible;

  .section {
    &-lead {
      max-width: 600px;
    }

    &-image {
      margin-bottom: $sub * -8;

      img {
        @include query(md) {
          margin-left: 5%;
        }
      }
    }
  }

  + .section {
    padding-top: $sub * 6;
  }
}
