&-achievments {
  padding-bottom: 0;

  .section {
    &-title {
      @include center(900px);
    }

    &-lead {
      max-width: 600px;
    }

    &-points {
      margin-bottom: $sub;

      @include query(md) {
        margin-bottom: $sub * 2;
      }

      [class*="col"] {
        padding-left: $sub;
        padding-right: $sub;
      }
    }
  }
}
