$green: #2eb373;

&-watchup {
  .section {
    &-supertitle {
      color: #000;

      &-border {
        border-bottom-color: $green;
      }
    }

    &-title {
      color: $green;
    }
  }
}
