.start-now {
  @include font-size(20px);
  display: block;
  padding: $sub;
  text-transform: uppercase;
  letter-spacing: 4px;

  @include query(sm) {
    padding: $sub * 2 $sub;
  }

  @include query(md) {
    padding: $sub * 2.5 $sub;
  }
}
