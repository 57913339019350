$helvetica:           "helvetica neue", helvetica, arial, sans-serif;

$font-light:          "TyponineSans Light 47", $helvetica;
$font-light-italic:   "TyponineSans Light Italic 13", $helvetica;
$font-regular:        "TyponineSans Regular 62", $helvetica;
$font-regular-italic: "TyponineSans Regular Italic 34", $helvetica;
$font-text:           "TyponineSans Text 22", $helvetica;
$font-text-italic:    "TyponineSans Text Italic 12", $helvetica;
$font-medium:         "TyponineSans Medium 40", $helvetica;
$font-medium-italic:  "TyponineSans Medium Italic 13", $helvetica;
$font-bold:           "TyponineSans Bold 33", $helvetica;
$font-bold-italic:    "TyponineSans Bold Italic 7", $helvetica;

