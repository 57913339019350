@font-face {
  font-family: 'fontawesome';
  src:url("../fonts/fontawesome.eot?e78owp");
  src:url("../fonts/fontawesome.eot?#iefixe78owp") format('embedded-opentype'),
      url("../fonts/fontawesome.woff?e78owp") format('woff'),
      url("../fonts/fontawesome.ttf?e78owp") format('truetype'),
      url("../fonts/fontawesome.svg?e78owp#fontawesome") format('svg');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.fa {
  font-family: 'fontawesome';
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.fa-2x { font-size: 2em; }
.fa-3x { font-size: 3em; }
.fa-4x { font-size: 4em; }
.fa-5x { font-size: 5em; }

.fa-spinner:before {
  content: "\e60b";
}
.fa-github:before {
  content: "\f09b";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-youtube:before {
  content: "\f16a";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-search:before {
  content: "\f002";
}
.fa-star:before {
  content: "\f005";
}
.fa-play:before {
  content: "\f04b";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-apple:before {
  content: "\f179";
}
.fa-android:before {
  content: "\f17b";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-chevron-circle-down:before {
  content: "\e609";
}
.fa-chevron-circle-up:before {
  content: "\e60a";
}
.fa-chevron-up:before {
  content: "\e605";
}
.fa-chevron-down:before {
  content: "\e606";
}
.fa-chevron-right:before {
  content: "\e607";
}
.fa-chevron-left:before {
  content: "\e608";
}
.fa-separator-border:before {
  content: "\e603";
}
.fa-separator-dots:before {
  content: "\e604";
}
.fa-carousel-active:before {
  content: "\e600";
}
.fa-carousel:before {
  content: "\e601";
}
.fa-close:before {
  content: "\e602";
}
