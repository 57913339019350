&-intro {
  background-image: url("/images/yogabuddy/bg.png");
  background-size: contain;
  background-repeat: repeat-x;
  background-color: #f35349;
  color: #fff;

  @include query(sm) {
    padding-bottom: $sub * 4;
    background-position: 50% 100%;
  }

  @include query(md) {
    padding-bottom: $sub * 1;
  }

  p {
    color: #fbd3d4;
  }

  a {
    color: #fff;
  }

  .section {
    &-supertitle {
      &-border {
        border-bottom-color: rgba(#fff, .5);
      }
    }

    &-lead {
      color: rgba(#fff, .7);
    }

    &-image {
      margin-bottom: $sub * -3;
      margin-right: $sub;

      @include query(xs) {
        margin-bottom: $sub * -4;
      }

      @include query(sm) {
        flex: 2.5;
      }

      @include query(md) {
        margin-bottom: $sub * -6;
      }
    }

    &-text {
      img {
        margin-bottom: $sub;
      }

      p {
        max-width: none;
      }
    }
  }

  .separator {
    &-dots {
      color: #000;
    }

    @include query(md) {
      margin-bottom: $sub * 1.5;
    }
  }

  + .section {
    padding-top: $sub * 4;

    @include query(sm) {
      padding-top: $sub * 2;
    }

    @include query(md) {
      padding-top: $sub * 6;
    }
  }
}
