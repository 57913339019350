$blue: #20b4c9;
$orange: #ff6c53;

&-achievments {
  background-color: #f0f0f0;

  .section {
    &-title {
      @include center(800px);
    }

    &-lead {
      @include center(600px);
      margin-bottom: $sub * 2;

      @include query(sm) {
        margin-bottom: $sub * 3;
      }
    }

    &-image {
      margin-bottom: $sub * 2;
    }

    &-options {
      @include center(800px + ($sub * 4));
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include query(md) {
        justify-content: space-between
      }

      img {
        margin: $sub;
      }
    }
  }

  .stats {
    @include center(730px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $sub * 2;

    @include query(sm) {
      justify-content: space-between;
      margin-bottom: $sub * 3;
    }
  }

  .stat {
    margin: $sub;

    &-value {
      @include font-size(23px);
      display: block;
      margin-bottom: $suh * 1.5;
      color: $orange;
      font-family: $font-regular;

      @include query(sm) {
        @include font-size(46px);
      }
    }

    &-name {
      @include font-size(14px, 0.5);
      font-family: $font-medium;
      text-transform: uppercase;
    }
  }
}
