a.arrow {
  position: relative;
  display: block;
  width: $arrow-size;
  height: $arrow-size;
  margin-left: auto;
  margin-right: auto;
  font-size: $arrow-size;
  line-height: $arrow-size;
  color: #fff;
  opacity: .25;
  transition: opacity .3s;

  &:hover,
  &:focus {
    opacity: 1;
  }
}
