.logo {
  $width: 118px;
  $height: 34px;

  @include logo("light");
  display: inline-block;
  width: $width + 1px;
  height: $height;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;

  &-blog {
    @include logo("blog-light");
  }
}
