$blue: #0d67bf;

&-doctor {
  .section {
    &-supertitle {
      &-border {
        border-bottom-color: $blue;
      }
    }

    &-title {
      color: $blue;
    }
  }
}
