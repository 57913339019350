// TyponineSans has font families instead of weights/styles
*, *::before, *::after {
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

/*
@include query(md) {
  .wf-loading {
    *, *::before, *::after, *::placeholder {
      color: transparent !important;
      text-shadow: none !important;
    }
  }
}
*/

body {
  @include font-smoothing;
  text-align: center;
}

main {
  min-height: 60vh;
}

h1, h2, h3, h4, h5, h6,
p, ol, ul {
  margin-top: 0;
  margin-bottom: $sub;
}

h1 {
  @include font-size(44px, 2);
  font-family: $font-bold;

  @include query(xs) {
    @include font-size(72px, 3);
  }

  @include query(sm) {
    @include font-size(96px, 3.5);
    margin-bottom: $sub * 2;
  }

  @include query(md) {
    @include font-size(120px, 5);
  }
}

h2 {
  @include font-size(28px, 1.5);
  margin-bottom: $sub;
  font-family: $font-bold;

  @include query(xs) {
    @include font-size(40px, 2);
  }

  @include query(sm) {
    @include font-size(56px, 2.5);
  }

  @include query(md) {
    @include font-size(65px, 3);
    margin-bottom: $sub * 1.5;
  }
}

h3 {
  @include font-size(24px, 1.5);
  margin-bottom: $suh;
  font-family: $font-light;

  @include query(xs) {
    @include font-size(30px, 1.5);
  }

  @include query(md) {
    @include font-size(34px, 1.75);
    margin-bottom: $sub;
  }

  &.is-bold {
    font-family: $font-bold;
  }
}

blockquote {
  padding: 0;
  border-left: 0;

  small {
    color: inherit;
    font-family: $font-regular;

    &::before {
      content: normal;
    }
  }
}

em, i, blockquote {
  font-family: $font-regular-italic;

  strong, b {
    font-family: $font-medium-italic;
  }
}

strong, b {
  font-family: $font-medium;

  em, i {
    font-family: $font-medium-italic;
  }
}
