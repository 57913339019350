&.is-btn-light {
  .no-touch & {
    background-color: transparent;
  }

  @include skrollr {
    .navbar-btn {
      border-color: #fff;
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
