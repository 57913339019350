&-landing {
  padding-top: $sub * 2;

  .navbar {
    .logo {
      @include logo("black");
    }
  }

  .section {
    &-title {
      color: $brand-primary;
    }
  }

  .btn {
    border-style: none;
    border-radius: 0px;
  }

  .form-control {
    border-radius: 0;
  }
}
