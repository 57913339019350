&.is-logo-invert {
  .no-touch & {
    background-color: transparent;
  }

  @include skrollr {
    .logo {
      @include logo("invert");

      &-blog {
        @include logo("blog-dark");
      }
    }
  }
}
