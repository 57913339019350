&-pepsi {
  @include bg-full("list-bg-red.jpg");
  background-color: #d8333d;
  color: #fff;

  .section {
    &-lead {
      color: rgba(#fff, .9);
    }

    &-image {
      margin-right: $sub !important;

      img {
        left: auto;
        right: 0;

        @media (max-width: $screen-xs-max) {
          max-width: 80%;
        }
      }
    }
  }

  .btn {
    border-color: #fff;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #f35349;
    }
  }
}
