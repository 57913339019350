&-solution {
  padding-bottom: 0;
  background-color: #e6e6e6;

  .container-fluid {
    position: relative;
    padding-bottom: $sub * 2;
  }

  .section {
    &-title {
      @include center(700px);
    }

    &-lead {
      @include center(500px);
      margin-bottom: $sub * 2;

      @include query(md) {
        margin-bottom: $sub * 4;
      }
    }

    &-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .img {
        &-inner {
          padding-bottom: 40%;

          @include query(md) {
            padding-bottom: 30%;
          }
        }
      }
    }

    &-image {
      position: relative;
      z-index: 10;
    }
  }
}
